export enum DatasourceTypeName {
    Controller = 'Controller',
    Gateway = 'Gateway',
    SIM = 'SIM',
    EcolabDataCollector = 'Ecolab Data Collector',
    LIMS = 'LIMS',
    EData = 'E-Data',
    CustomerData = 'Customer Data',
    Sensor = 'Sensor',
    Zigbee = 'Zigbee',
    PartnerData = 'Partner Data',
    DownstreamEData = 'Downstream E-Data',
    Unknown = '', // For unmatched cases
  }
  export enum ControllerSubTypes {
    SubType1 = 1,
    SubType2 = 2,
    SubType3 = 3,
    SubType4 = 4,
    SubType10 = 10,
  }
  
  export enum GatewaySubTypes {
    SubType5 = 5,
    SubType6 = 6,
    SubType8 = 8,
  }
  
  export enum SensorSubTypes {
    SubType7 = 7,
    SubType9 = 9,
  }
  
  export enum CustomerDataSubTypes {
    SubType6 = 6,
    SubType24 = 24,
    SubType31 = 31,
    SubType37 = 37,
    SubType38 = 38,
  }
  
  export enum SpecialSubTypes {
    SubType35 = 35,
    SubType65 = 65,
    SubType66 = 66,
  }
  