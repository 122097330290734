<div class="modal-header pt-4 pl-4 mb-0 border-0">

    <h3 class="modal-title delete-txt-icon">{{modalText}}</h3>
    <button type="button" *ngIf="closeIcon" class="close" aria-label="Close" (click)="disablebtn? activeModal.close(): cancelAddEditModal();">
        <img src="../../../../assets/images/modal-close-icon.svg" alt="close" />
    </button>
  </div>
 
  <p class="revert mb-0" [innerHtml]="modcontent"></p>
  <div class="modal-body p-0" *ngIf="showModalBody" [ngStyle]="{'min-height' : showTextArea ? 'auto' : ''}">
    <div class="mx-4 mb-4" *ngIf="showModalBody && showTextArea">
      <p>{{textAreaLabel}}</p>
      <textarea [placeholder]="textAreaPlaceholder" cols="54" rows="4" [(ngModel)]="deleteReason"></textarea>
    </div>
  </div>
  <div class="modal-footer mt-0">
    <ng-container *ngIf="!(showModalBody && showTextArea); else deleteWithReasonTemplate">
      <button type="button" class="btn btn-secondary" (click)="delete()" [ngClass]="[disablebtn ===true  ? 'display-block'  : 'display-none']">{{'COMMON.DELETE' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="cancelAddEditModal()" [ngClass]="[localDriverBtn ===true  ? 'display-none'  : 'display-block']">{{disablebtn == true ? 'Cancel' : 'Close'}}</button>
      <button type="button" class="btn btn-primary" (click)="cancelAddEditModal()" [ngClass]="[localDriverBtn ===true  ? 'display-block'  : 'display-none']">{{'COMMON.OK' | translate}}</button>
    </ng-container>
    <ng-template #deleteWithReasonTemplate>
      <button type="button" class="btn btn-outline-primary" (click)="cancelAddEditModal()">{{'COMMON.CANCEL' | translate}}</button>
      <button type="button" class="btn btn-danger" (click)="delete()" [style.backgroundColor]="'red'" [disabled]="showTextArea && !deleteReason" [ngStyle]="{'cursor' : (showTextArea && !deleteReason) ? 'not-allowed' : ''}">{{'COMMON.DELETE_ACTION' | translate}}</button>
    </ng-template>
  </div>
  