<div class="mt-3">
  <div class="site-Heading float-width-title">
    <span
      class="mb-0 float-left sensor-title">{{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.HEADER' | translate}}</span>
    <div class="sensor-type-view mobile-width-100 float-left asset-port-spacing">
      <label class="overflow-ellipsis asset-name-width" #element1 [ngbTooltip]="element1.scrollWidth-1 > element1.offsetWidth ? assetNameData : ''">
        {{'WATER_FLOW_INTELLIGENCE.SITE_OVERVIEW.CFR_DETAILS.ASSET'| translate}}: {{assetNameData}}
      </label>
    </div>
    <div class="sensor-type-view mobile-width-100 float-left asset-port-spacing">
      <label class="overflow-ellipsis port-name-width" #element2 [ngbTooltip]="element2.scrollWidth-1 > element2.offsetWidth ? portNameData : ''">
        {{'WATER_FLOW_INTELLIGENCE.SITE_OVERVIEW.CFR_DETAILS.PORT'| translate}}: {{portNameData}}
      </label>
    </div>
  </div>

  <nalco-tile [parentInstance]="myParentInstance()"   *ngIf="selectedSiteID" [showMoreOptions]=false [errorData]='errorInfo' onclick="void(0)"
    [showExportAsCsv]=false [showInfoIcon]=false [headerTitle]="" class="no-header main-tabs float-width-title">
    <div tile-content>
      <div class="sensor-mapping-form " [ngClass]="{'setHeight': showDeviceTimeZoneDd }">
        <div class="row">
          <!-- <span class="error-text">This user don't have sensor mapping permission.</span> -->
          <div class="col-12">
            <div class="float-left mobile-width-100 node-sensor-form">
              <div class="node-sensor-form-icon">
                <span class="ico-nav-settings2"></span>
              </div>
              <span class="position-relative inline-block-display-moz">
                <p class="start-label" #element1 [ngbTooltip]="element1.scrollWidth > element1.offsetWidth + 1 ? 
                  ('WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.NODE'| translate) : ''">
                  {{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.NODE'| translate}}
                </p>
                <input type="text" [disabled]="stopmappingFlag" class="input-box" [(ngModel)]="NodeValue">
                <span *ngIf="!stopmappingFlag" class="validate-node" (click)="validateNode(true);">
                  <span [className]="'ico-status ' + successImageFlag"></span>
                  {{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.VALIDATE'| translate}}
                </span>
              </span>

            </div>
            <div class="float-left channel mobile-width-100">
              <nalco-dropdown class="dropdown-form margin-left-20" [isDisabled]="disabled || stopmappingFlag"
                [ddData]="selectChannelView" (ddChange)="updateDefaultChannel($event);"></nalco-dropdown>
            </div>
            <div class="float-left margin-left-40 mobile-width-100">
              <span class="position-relative block-display-moz">
                <p class="start-label" #element2 [ngbTooltip]="element2.scrollWidth > element2.offsetWidth + 1 ? 
                  ('WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.INPUT_MODE'| translate) : ''">
                  {{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.INPUT_MODE'| translate}}</p>
                <input type="text" class="input-box" [disabled]="true" [(ngModel)]="SensorFormData.Inputmode">
              </span>

            </div>
            <div class="float-left channel mobile-width-100 sensor-form-dropdown">
              <nalco-dropdown class="dropdown-form margin-left-20"
                [isDisabled]="disableMeasureType || disabled || stopmappingFlag" [ddData]="SensorFormData.MeasureType"
                (ddChange)="UpdatesSensorPipeSize($event);avoidMesurementMapping()"></nalco-dropdown>
            </div>
          </div>

        </div>
        <p class="margin-left-24-error" *ngIf="errorTwiceMeasuretoAPort !== ''"> {{errorTwiceMeasuretoAPort}} </p>
        <p class="margin-left-24-error" *ngIf="errorMsgUsedChannel"> {{errorMsgUsedChannel}} </p>
        <p class="margin-left-24-error" *ngIf="errorMsgChannelAlreadyMapped"> {{errorMsgChannelAlreadyMapped}} </p>
        <p class="margin-left-24-error" *ngIf="flagPortDiffMAC"> {{errorMsgPortDiffMAC}} </p>
        <div class="row margin-top-space float-left">
          <div class="col-auto">
            <div class="sensor-type-view mobile-width-100 float-left">
                <nalco-dropdown class="dropdown-form"
                  [isDisabled]="disableMeasureType || disabled || errorTwiceMeasuretoAPort !== ''"
                  [ddData]="selectSensorTypeView" (ddChange)="assignSensorData($event);detectChange();">
                </nalco-dropdown>
            </div>
            <div class="channel mobile-width-100 pipe-size">
              <nalco-dropdown class="dropdown-form margin-left-20"
                [isDisabled]="disableMeasureType || disabled || !isSensorSelected || stopmappingFlag"
                [ddData]="selectpipeSizeView" (ddChange)="updatePipeSizeView($event);detectChange();"></nalco-dropdown>
            </div>


            <div class="channel mobile-width-100 channel-position-css">
              <nalco-dropdown *ngIf="SensorFormData.SelectedFlowData.KfactorEditType === 1 && (!hideIfKeyenceisSelected || CustomPipeSize)"
              
                class="dropdown-form margin-left-20" [isDisabled]="disabled || stopmappingFlag"
                [ddData]="selectKFactorView" (ddChange)="updateKFactorView($event);detectChange();"></nalco-dropdown>
              <p style="left: 1.7rem;"   *ngIf="SensorFormData.SelectedFlowData.KfactorEditType === 2 && (!hideIfKeyenceisSelected  || CustomPipeSize)"
                class="start-label" #element3 [ngbTooltip]="element3.scrollWidth > element3.offsetWidth + 1 ? 
                ('WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.KFACTOR'| translate) : ''">
                {{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.KFACTOR'| translate}}
              </p>
                <input style="margin-left: 10px;"
                (keypress)="validationNumberRange($event, SensorFormData.SelectedFlowData)"
               
                *ngIf="SensorFormData.SelectedFlowData.KfactorEditType === 2 && (!hideIfKeyenceisSelected  || CustomPipeSize)"
                [ngModel]="SensorFormData.SelectedFlowData.HighValue | number : '1.0-2'"
                (ngModelChange)="SensorFormData.SelectedFlowData.HighValue = ($event && $event != '-') ? changeScaleValue($event) : SensorFormData.SelectedFlowData.HighValue;detectChange();"
                type="text" class="input-box"
                (input)="kfactorInputValidation($event, SensorFormData.SelectedFlowData)" />
              <p class="k-error" *ngIf="SensorFormData.SelectedFlowData.KfactorEditType === 2 && errorHighLow">
                {{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.KFACTOR_ERROR'| translate}}
                ({{SensorFormData.SelectedFlowData.HighValueMin}},
                {{SensorFormData.SelectedFlowData.HighValueMax}})
              </p>
            </div>
            <div class="float-left mobile-width-100">
              <span class="position-relative ml-10 block-display-moz">   
                    <p class="start-label" #rssi [ngbTooltip]="getTooltip(rssi)">RSSI (MW)</p>             
                    <input type="text" class="input-box width-110" [disabled]="true" [(ngModel)]="Rssi">
              </span>
            </div>
            <div class="channel mobile-width-100 channel-space">

            </div>

          </div>
        </div>
        <div class="row margin-top-space float-left">
          <div class="scale-section">
            <div class="scale-wrapper" *ngIf="!CustomPipeSize">
              <div>
                <div class="float-left mobile-width-100 " *ngIf="channelValue">
                  <span class="position-relative block-display-moz">
                    <p class="start-label ScalGal-low" #scaleLow [ngbTooltip]="getTooltip(scaleLow)">
                      {{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.SCALE_LOW'| translate}}
                      <span
                        *ngIf="SensorFormData.SelectedFlowData.UnitSymbol !== ''">({{SensorFormData.SelectedFlowData.UnitSymbol}})</span>
                    </p>
                    <input type="text" class="input-box width-110"
                      [disabled]="disabled || keyenceScaleLowFlag || stopmappingFlag"
                      (input)="validateScaleInputValues();" (keypress)="validationNumberNegative($event)"
                      (ngModelChange)="SensorFormData.SelectedFlowData.LowValue = ($event && $event != '-') ? changeScaleValue($event) : SensorFormData.SelectedFlowData.LowValue;detectChange();"
                      [ngModel]="SensorFormData.SelectedFlowData.LowValue | number : '1.0-4'">
                  </span>

                </div>
                <div class="float-left mobile-width-100" [ngClass]="channelValue ? 'margin-left-20' : ''">

                  <span class="position-relative block-display-moz" >
                    <p class="start-label ScalGal-high" *ngIf="!hideIfKeyenceisSelected" #element6 [ngbTooltip]="getTooltip(element6)">
                      {{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.SCALE'| translate}} 
                      <span *ngIf="channelValue">{{'WATER_FLOW_INTELLIGENCE.SITE_OVERVIEW.CFR_DETAILS.HIGH' | translate}}</span>
                      <span *ngIf="SensorFormData.SelectedFlowData.UnitSymbol !== ''">({{SensorFormData.SelectedFlowData.UnitSymbol}})</span>
                    </p>
                    <div *ngIf="hideIfKeyenceisSelected === true"><p class="start-label" #element7 [ngbTooltip]="element7.scrollWidth > element7.offsetWidth + 1 ? 
                      ('WATER_FLOW_INTELLIGENCE.SITE_PERFORMANCE_LIST.VOLUME/PULSE'| translate) : ''">
                      {{'WATER_FLOW_INTELLIGENCE.SITE_PERFORMANCE_LIST.VOLUME/PULSE'| translate}}</p></div>
                    <input type="text" class="input-box width-110" [disabled]="disabled || stopmappingFlag"
                      (input)="validateScaleInputValues();" (keypress)="validationNumberNegative($event)"
                      (ngModelChange)="SensorFormData.SelectedFlowData.HighValue = ($event && $event != '-') ? changeScaleValue($event) : SensorFormData.SelectedFlowData.HighValue;detectChange();"
                      [ngModel]="SensorFormData.SelectedFlowData.HighValue | number : '1.0-4'">
                  </span>
                </div>
                <div class="sensor-mapping-unit-flow-ddl float-left mobile-width-100 margin-left-20">
                  <div *ngIf="keyenceScaleLowFlag === false && currentMeaurementType?.value == 5 && !disabled">
                    <nalco-dropdown [ddData]="unitsFlowDropdown" [isDisabled]="disabled || stopmappingFlag"
                      (ddChange)="unitChange($event, SensorFormData.SelectedFlowData);detectChange();"></nalco-dropdown>
                  </div>
                  <div *ngIf="keyenceScaleLowFlag === false && currentMeaurementType?.value == 25 && !disabled">
                    <nalco-dropdown [ddData]="unitsVolumeDropdown" [isDisabled]="disabled || stopmappingFlag"
                      (ddChange)="unitChange($event, SensorFormData.SelectedFlowData);detectChange();"></nalco-dropdown>
                  </div>
                </div>
              </div>
              <p class="margin-left-0-errorScale" *ngIf="errorMsgScaleFlag">
                {{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.LOW_VLAUE_LESS_HIGH'| translate}}</p>
              <div>
              </div>

            </div>

          </div>

          <div class="input-offset-section">

            <div class="float-left margin-left-20 mobile-width-100">
              <span class="position-relative block-display-moz" *ngIf='hideIfKeyenceisSelected === false && hideIfCustomerisSelected === false'>
                <p class="start-label" #element8 [ngbTooltip]="getTooltip(element8)">
                  {{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.INPUT_MIN'| translate}} <span
                    *ngIf="scaleUnit !== ''">({{scaleUnit}})</span></p>
                <input type="text" class="input-box width-110" [disabled]="true" (input)="validateScaleInputValues();"
                  (keypress)="validationNumberNegative($event)" [(ngModel)]="SensorFormData.SelectedFlowData.InputMin"
                  (ngModelChange)="detectChange();">
              </span>
              <p class="margin-left-0-error" *ngIf="errorMsgInputFlag">
                {{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.INPUT_MIN_LESS_MAX'| translate}}</p>
            </div>
            <div class="float-left margin-left-20 mobile-width-100" >
              <span class="position-relative block-display-moz" *ngIf='hideIfKeyenceisSelected === false && hideIfCustomerisSelected === false'>
                <p class="start-label" #element9 [ngbTooltip]="getTooltip(element9)">
                  {{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.INPUT_MAX'| translate}} <span
                    *ngIf="scaleUnit !== ''">({{scaleUnit}})</span></p>
                <input type="text" class="input-box width-110" [disabled]="true" (input)="validateScaleInputValues();"
                  (keypress)="validationNumberNegative($event)" [(ngModel)]="SensorFormData.SelectedFlowData.InputMax"
                  (ngModelChange)="detectChange();">
              </span>

            </div>
            <div class="float-left margin-left-20 mobile-width-100">

              <span class="position-relative block-display-moz" *ngIf='hideIfKeyenceisSelected === false && hideIfCustomerisSelected === false'>
                <p class="start-label" #element10 [ngbTooltip]="getTooltip(element10)">
                  {{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.OFFSET'| translate}} <span
                    *ngIf="scaleUnit !== ''">({{scaleUnit}})</span></p>
                <input type="text" class="input-box width-110" (keypress)="validationNumberNegative($event)"
                  [disabled]="true" [(ngModel)]="SensorFormData.SelectedFlowData.Offset"
                  (ngModelChange)="detectChange();">
              </span>

            </div>
            <div class="float-left margin-left-20 mobile-width-100">
              <span class="position-relative block-display-moz">
                <p class="start-label" #element11 [ngbTooltip]="element11.scrollWidth > element11.offsetWidth + 1 ? 
                  ('COMMON.START_DATE'| translate) : ''">{{'COMMON.START_DATE' | translate}}</p>
                <kendo-datepicker [(value)]="SensorFormData.StartDate"
                  (valueChange)="onValueChange($event);detectChange();" [disabled]="disabled || stopmappingFlag"
                  placeholder="month/day/year" #element4 placement='bottom'>
                  <ng-template kendoCalendarMonthCellTemplate let-date>
                    <span [class.disabled]="isDateDisabled(date)">{{date.getDate()}}</span>
                  </ng-template>
                </kendo-datepicker>
              </span>
            </div>
            <div *ngIf="stopmappingFlag && isAdmin" class="float-left margin-left-20 mobile-width-100">
              <span class="position-relative block-display-moz">
                <p class="start-label" #element12 [ngbTooltip]="element12.scrollWidth > element12.offsetWidth + 1 ? 
                  ('COMMON.END_DATE'| translate) : ''">{{'COMMON.END_DATE' | translate}}</p>
                <kendo-datepicker [(value)]="SensorFormData.EndDate" placeholder="" #element5 placement='bottom'
                  (valueChange)="onEndDateChange($event);detectChange();">
                  <ng-template kendoCalendarMonthCellTemplate let-date>
                    <span [class.disabled]="isEndDateDisabled(date)">{{date.getDate()}}</span>
                  </ng-template>
                </kendo-datepicker>
              </span>
            </div>

          </div>

        </div>

        <div class="row margin-top-space col-12">
          <div class="col-12">


            <div class="float-left checkBox-div">

                    <!-- <label class="form-label dropdown-label margin-left-20"><input type="checkbox" class="input-box" checked [disabled]="true">
                <div class="checkbox-form float-left"></div> Include In Rollup
              </label> -->


            </div>
          </div>
          <!-- <div>{{CustomPipeSize}}</div> -->
        </div>

        <div *ngIf="showDeviceTimeZoneDd" class="timeZoneDiv">
          <nalco-dropdown class="dropdown-form" [ddData]="timeZoneDdData" (ddChange)="updateTimeZone($event)" [isDisabled]="disableTimeZoneDd || disabled"></nalco-dropdown>
        </div>

        <div *ngIf="isAdmin" class="row margin-top-space admin-button-spacing col-12 float-left mt-5">
          <input type="button" [disabled]="stopmappingFlag" #btn1 
            [ngbTooltip]="btn1.scrollWidth-1 > btn1.offsetWidth ? ('WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.STOP_MAPPING' | translate) : ''"
            *ngIf="(!avoidMesurementMappingFlag && successImageFlag === 'checked' && disableMeasureType)"
            (click)="deleteMapping('stop_mapping')" class="float-left adminbtn btn btn-secondary mr-3"
            value="{{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.STOP_MAPPING' | translate}}">

          <input type="button" [disabled]="stopmappingFlag" #btn2
          [ngbTooltip]="btn2.scrollWidth-1 > btn2.offsetWidth ? ('WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.DELETE_MAPPING' | translate) : ''"
            *ngIf="(!avoidMesurementMappingFlag && successImageFlag === 'checked' && disableMeasureType)"
            (click)="deleteMapping('delete')" class="float-left adminbtn btn btn-secondary mr-3"
            value="{{'WATER_FLOW_INTELLIGENCE.SENSOR_MAPPING.SENSOR_CONFIGURATION.DELETE_MAPPING' | translate}}">

          <input type="button" class="float-right btn btn-primary" #btn3
          [ngbTooltip]="btn3.scrollWidth-1 > btn3.offsetWidth ? ('COMMON.SUBMIT' | translate) : ''"
            [disabled]="disableSubmitBtn()"
            (click)="SaveSensorForm(SensorFormData)" value="{{'COMMON.SUBMIT' | translate}}">
          <input type="button" (click)="CancelSensorForm()" class="float-right adminbtn btn btn-secondary mr-3"
            value="{{'COMMON.CANCEL' | translate}}" #btn4
            [ngbTooltip]="btn4.scrollWidth-1 > btn4.offsetWidth ? ('COMMON.CANCEL' | translate) : ''">
        </div>
      </div>
    </div>
  </nalco-tile>
</div>

<!-- mapped sensor to mac isAlreadyMapped   disableMeasureType -->
<!-- {{disableMeasureType}}
  {{isAlreadyMapped}}  -->
