import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { IntlService } from "@progress/kendo-angular-intl";
import { FileInfo, FileRestrictions, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';
import * as moment from 'moment';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppEnvConfig } from '../../../app.env.config';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { LoggerService } from '../../../core/logger/global-logger.service';
import { AIGeneratedUserID } from '../../../shared-action-log-grid/constants';
import { DimensionalUnit, EroiTypes } from '../../../shared-financial-impact-refresh/models/EroiTypes';
import { EroiTypesWithUnitMappings } from '../../../shared-financial-impact-refresh/models/EroiTypesWithUnitMappings';
import { EroiUnitMappings } from '../../../shared-financial-impact-refresh/models/EroiUnitMappings';
import { CurrencyDetails } from '../../../shared-main/models/CurrencyDetails';
import { ConfirmationPopupComponent } from '../../../shared/components/confirmation-popup/confirmation-popup.component';
import { ErrorHandling } from '../../../shared/components/error-handling/error-handling';
import { XdComponentState } from '../../../shared/components/xd-container/xd-component';
import { ComponentState } from '../../../shared/components/xd-container/xd-component-state';
import { ConversionUtilityService } from '../../../shared/services/coversionUtility.service';
import { ActionImpactType, ActionStatusType, SourceApplicationTypeId } from '../../_enum/constants';
import { AccountDetail, ActionLog, ActionLogCCUser, ActionLogUser, ActionsTaken, FileAttachments, FilterActionLog, SavingCategoryTypes, Savings } from '../../models/ActionLog';
import { ActionLogService } from '../../services/action-log.service';
import { IEroiUnitMappings } from './../../../shared-financial-impact-refresh/contracts/IEroiUnitMappings';

@Component({
  selector: 'nalco-action-log-grid-details',
  templateUrl: './action-log-grid-details.component.html',
  styleUrls: ['./action-log-grid-details.component.scss']
})
export class ActionLogGridDetailsComponent extends XdComponentState implements OnInit, OnDestroy {
  assetDetailsForm: UntypedFormGroup;
  public formGroup: UntypedFormGroup;
  actionDesc = new UntypedFormControl("");
  subGridUserEmail = new UntypedFormControl("");
  actionTakenDate = new UntypedFormControl(new Date());
  valueDetailsForm: UntypedFormGroup;
  userSettings: any;
  person: ActionLogUser;
  subscriptions: Subscription[] = [];
  isTVDEnabled: boolean;
  defaultUserPreference: any;
  selectedDisplayCurrency: CurrencyDetails;
  selectedEroi: Array<EroiTypesWithUnitMappings> = [];
  tvdWritePermission: boolean;
  valueDetailsSaveButtonEnabled: boolean = false;
  valueDetailsDataChanged: boolean;
  accountDetails: Array<AccountDetail> = [];
  get translate() {
    return this.translateService;
  }
  constructor(private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private router: Router,
    private logger: LoggerService,
    private httpClient: HttpClient,
    private actionLogService: ActionLogService,
    private localCacheService: LocalCacheService,
    private elem: ElementRef,
    private intl: IntlService,
    private dataService: ConversionUtilityService,
    private config: AppEnvConfig,
    private cdr: ChangeDetectorRef,) {
    super();
    this.isTVDEnabled = config.getEnv('IsTVDEnabled') && config.getEnv('IsTVDEnabled').toString() === 'true' ? true : false;
    this.userSettings = JSON.parse(this.localCacheService.fetchData('UserSettings'));
    this.defaultUserPreference = this.userSettings.UserPreference;
    this.createForm();
  }

  @Input() actionLog: ActionLog;
  // add, edit, readonly modes only
  @Input() mode: string;
  @Input() view: string;
  @Input() duration?: number;
  @Input() hostView?: string;
  @Input() contextPointData?: any;
  @Input() customEndDate?: string;
  @Input() customStartDate?: string;
  @Input() allAvlblDataToggle?: boolean = false;
  @Input() filterCriteria?: FilterActionLog;
  @Input() isSiteView?: boolean = false;
  @Input() savingCategoryTypesData: SavingCategoryTypes[] = [];
  @Input() eroiTypes: EroiTypes[] = [];
  @Input() displayUnitSymbols: IEroiUnitMappings[] = [];
  @Input() currencyData: CurrencyDetails[] = [];
  @Input() isFIScreen?: boolean;
  @Input() widgetId?;
  @Input() assetIdList: number[] = [];
  @Input() defaultAssetname: any;
  @Input() fromEdata?: boolean;
  @Output() cancelChanges = new EventEmitter<any>();
  @Output() changesSaved = new EventEmitter<any>();
  @Output() isChanged = new EventEmitter<boolean>();
  @Output() resetToZero = new EventEmitter<number>();

  private editedRowIndex: number;
  private data: any[] = [];


  actionStatusName = [
    { 'value': 'Open', 'label': 'ACTION_LOG.OPEN' },
    { 'value': 'Closed', 'label': 'ACTION_LOG.CLOSED' },
    { 'value': 'Deferred', 'label': 'ACTION_LOG.DEFERRED' }
  ];

  impactName = [
    { 'value': 'Safety', 'label': 'ACTION_LOG.SAFETY' },
    { 'value': 'High', 'label': 'ACTION_LOG.HIGH' },
    { 'value': 'Medium', 'label': 'ACTION_LOG.MEDIUM' },
    { 'value': 'Low', 'label': 'ACTION_LOG.LOW' }
  ];

  public listItems: Array<ActionLogCCUser> = [];
  selectAllCheck: any = false;
  noDataFound: any = "No User Selected";
  private ccfilterupdated: Subject<any> = new Subject<any>();
  private ccsubscription: Subscription = null;
  // dropdown for site and asset
  pageIndex = 1;
  assetsLoading = false;
  selectedAssetOnInit = [];
  disableFlag = false;
  assetData = [];
  assetDataDropdown = [];
  assetCategoryData = [];
  siteData: Array<any>;
  SiteDropdownData = [];
  siteSearchText = '';
  txtQueryChanged: Subject<string> = new Subject<string>();
  sitesLoading = false;
  selectedSiteOnInit = [];
  depValue = false;
  fromInit = true;
  selectedSiteforAvailParams = '';
  selectedSiteDetails: any;
  selectedParameters = [];
  showFavFlag = false;
  selectedDagId = -1;
  modalRef: any;
  systemData: Array<any>;
  isDisable = true;
  isError = false;
  errorHandling = new ErrorHandling(this.modalService, this.translateService);
  errorInfo: any;
  selectedAssetDetails: any;
  selectedSiteName: string = '';
  selectedAssetName: string = '';
  fileName: string = '';
  fileData = [];
  actionStatusColl = ActionStatusType;
  impactStatusColl = ActionImpactType;;
  selectedActionStatus: any;
  selectedImpactStatus: any;
  impactType: string;
  actionStatusType: string;
  recommendationText: string;
  isExpand: string = 'add';
  newAttachment: FileAttachments = new FileAttachments();

  // responsible person search
  selectedView = 'user';
  searchText = '';
  private filterupdated: Subject<any> = new Subject<any>();
  private subscription: Subscription = null;
  usersList: Array<ActionLogUser> = [];
  subgridUser: ActionLogUser;
  parentUser: ActionLogUser;
  loadingUserName: boolean = false;
  loadingCcUserName: boolean = false;
  loadingUserNameSubgrid: boolean = false;
  tog: boolean = false;
  subGridUserIsPresent: boolean = false;
  disableAdd: boolean = false;
  public fileTypeRestrictions: FileRestrictions = {
    maxFileSize: 10485760,
    allowedExtensions: ['JPEG', 'JPG', 'GIF', 'PNG', 'PDF', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx']
  };
  isChecked: boolean = false;
  actionDetailsDataChanged: boolean = false;
  defaultDate;
  defaultExpectedResDate = null;
  isActionTakenPresent: boolean = false;
  dateFormat: string;
  activeTabId = 'action-details';
  showIndustryNorms: boolean = false;
  isSaveButtonEnabled: boolean = true;
  isAIGenerated: boolean;
  resetValue = 0;

  ngOnInit() {
    this.resetToZero.emit(this.resetValue);
    let activeTab = this.localCacheService.fetchData('actionDetailsActiveTab');
    if (this.mode === 'edit' && activeTab) {
      this.activeTabId = activeTab;
    }
    this.tvdWritePermission = this.userSettings.IsInternalUser;
    this.selectedDisplayCurrency = this.currencyData.find(currency => currency.CurrencyCodeId === this.valueDetailsForm.get('DisplayCurrency').value);
    this.defaultDate = new Date()
    this.setState(ComponentState.ready);
    this.fromInit = true;
    this.depValue = false;
    this.sitesLoading = true;
    this.fromInit = false;
    this.txtQueryChanged
      .pipe(debounceTime(1000) // wait 1 sec after the last event before emitting last event
        , distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe(model => {

        this.siteSearchText = model;
        // Call your function which calls API or do anything you would like do after a lag of 1 sec
        this.getSites(this.siteSearchText, true);
      });
    if (this.mode === 'add') {
      this.assetDetailsForm.get('NotifyEmail').disable();
      if (this.hostView === 'inventory') {
        this.actionLogService.currentSwitchValue = false;
      }
      this.sitesLoading = false;
      if (!this.userSettings.HasNalcoAccesUser) {
        this.sitesLoading = true;
        this.getSites('');
      }
      this.actionLog = new ActionLog();
      this.actionLog.ActionsTaken = [];
      this.selectedSiteOnInit = [];
      this.selectedAssetOnInit = [];
      //default value should be open for action status drowpdown
      this.assetDetailsForm.controls['actionStatus'].setValue('Open');
      this.assetDetailsForm.controls['createdDate'].setValue(this.defaultDate);
      this.assetDetailsForm.invalid;
    } else if (this.mode === 'edit') {
      this.parentUser = this.actionLog.ResponsiblePerson;
      if (this.actionLog.ExpectedResolutionDate && this.actionLog.ExpectedResolutionDate !== null) {
        this.defaultExpectedResDate = new Date(moment(this.actionLog.ExpectedResolutionDate).utc().toString());
        this.assetDetailsForm.controls['expectedResDate'].setValue(this.defaultExpectedResDate);
      }
      else {
        this.defaultExpectedResDate = null;
        this.assetDetailsForm.controls['expectedResDate'].setValue(this.defaultExpectedResDate);
      }
      //enable or disable checkbox based on status of actionStatus
      if (this.actionLog.ActionStatusTypeName === "Open") {
        this.assetDetailsForm.get('NotifyEmail').enable();
      } else {
        this.assetDetailsForm.get('NotifyEmail').disable();
      }

      let action_log = [];
      this.actionLog.RecommendationNotificationRecipientsList.forEach((action) => {
        action_log.push(action.NotificationRecipient)
      });
      this.assetDetailsForm.controls['ccPersons'].setValue(action_log);
      this.assetDetailsForm.controls['NotifyEmail'].setValue(this.actionLog.EmailSentDate != null);
    }
    this.isChecked = this.actionLog.EmailSentDate != null ? true : false;
    this.subscription =
      this.filterupdated.asObservable()
        .pipe(debounceTime(500))
        .pipe(distinctUntilChanged())
        .subscribe((filter: any) => {
          if (filter !== null && (filter.value.trim().length > 2)) {
            this.getUserSearchForUserView(filter.value.trim(), filter.type);
          }
        });
    this.ccsubscription =
      this.ccfilterupdated.asObservable()
        .pipe(debounceTime(500))
        .pipe(distinctUntilChanged())
        .subscribe((filter: any) => {
          if (filter !== null && (filter.value.trim().length > 2)) {
            this.getUserSearchForCcUserView(filter.value.trim(), filter.type);
          }
        });
    this.userSettings = JSON.parse(this.localCacheService.fetchData('UserSettings'));
    let isUSuserCulture: boolean;
    if (this.userSettings) {
      isUSuserCulture = this.userSettings.Culture == 'en-US' ? true : false;
    }
    this.dateFormat = isUSuserCulture ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
    if (this.hostView === 'siteView' || this.hostView === 'inventory' || (this.hostView === 'SSD' && this.isSiteView) || this.hostView === 'systemView') {
      if (this.contextPointData && this.contextPointData.siteId) {
        this.selectedSiteDetails = {
          SiteID: this.contextPointData.siteId,
          SiteName: this.contextPointData.siteName,
          Street: '',
          SiteLocation: ''
        };
        if (this.mode === 'add') {
          this.loadAssetsForSiteView(this.contextPointData.siteId);
        }
      }
    }
    else if (this.hostView === 'actionlog' && this.filterCriteria.siteName !== '') {
      this.selectedSiteDetails = {
        SiteID: this.filterCriteria.siteId,
        SiteName: this.filterCriteria.siteName,
        Street: '',
        SiteLocation: ''
      };
      if (this.mode === 'add') {
        this.loadAssetsForSiteView(this.filterCriteria.siteId);
      }
    }
    this.assetDetailsForm.valueChanges.subscribe(data => {
      if (this.mode === 'edit' && this.parentUser !== undefined) {
        // this.assetDetailsForm.markAsPristine();
        this.checkNewlyAddedAction();
        let tempStatus = data.actionStatus === '' ? this.actionLog.ActionStatusTypeName : data.actionStatus;
        if (tempStatus !== "Open") {
          this.assetDetailsForm.get('NotifyEmail').disable({ onlySelf: true });
          if (this.actionLog.EmailSentDate === '' || this.actionLog.EmailSentDate === null)
            this.assetDetailsForm.controls.NotifyEmail.setValue(false, { emitEvent: false });
          else
            this.assetDetailsForm.controls.NotifyEmail.setValue(true, { emitEvent: false });
          //this.isChecked = false;
        } else if (tempStatus === "Open") {
          this.isChecked = this.assetDetailsForm.get('NotifyEmail').value;
          this.assetDetailsForm.get('NotifyEmail').enable({ onlySelf: true });
        }
      }
      if (this.mode === 'add') {
        if (this.assetDetailsForm.value.actionStatus == "Open" && this.selectedSiteDetails && this.selectedSiteDetails.SiteID) {
          this.assetDetailsForm.get('NotifyEmail').enable({ onlySelf: true });
        } else {
          this.assetDetailsForm.get('NotifyEmail').disable({ onlySelf: true });
          this.assetDetailsForm.controls.NotifyEmail.setValue(false, { emitEvent: false });
          this.isChecked = false;
        }
      }
      this.isChanged.emit(true);
      this.actionDetailsDataChanged = true
      this.actionLogService.changeDirty(true);
    })
    this.actionDesc.valueChanges.subscribe(data => {
      this.validateAddButton();
      if (data !== null) {
        this.isChanged.emit(true);
        this.actionLogService.changeDirty(true);
      }
    })
    this.valueDetailsForm.valueChanges.subscribe(data => {
      data.Savings = this.savings.controls.map(control => control.value)
      this.checkFormValidity(data);
    });

    this.valueDetailsForm.get('DisplayCurrency').valueChanges.subscribe(data => {
      this.selectedDisplayCurrency = this.currencyData.find(currency => currency.CurrencyCodeId === data);
    });
    this.isAIGenerated = (this.actionLog.CreatedBy == AIGeneratedUserID) ? true : false;
    if (this.fromEdata) {
      this.assetDetailsForm.get('assetName')?.setValue(this.defaultAssetname);
      this.openedFromEdata();
    }
  }

  checkFormValidity(valueData) {
    this.valueDetailsDataChanged = this.actionDetailsDataChanged ? true : false;
    this.valueDetailsSaveButtonEnabled = false;
    if (this.actionLog.SavingList.length > 0 && valueData.DisplayCurrency !== this.actionLog.SavingList[0].DisplayCurrency) {
      this.valueDetailsSaveButtonEnabled = true;
      this.valueDetailsDataChanged = true;
    }
    if (this.actionLog.SavingList.length > 0 && valueData.AccountDetails !== this.actionLog.AccountContextPointId) {
      this.valueDetailsSaveButtonEnabled = true;
      this.valueDetailsDataChanged = true;
    }
    if (this.actionLog.SavingList.length > valueData.Savings.length) {
      this.valueDetailsSaveButtonEnabled = true;
      this.valueDetailsDataChanged = true;
    } else if (this.actionLog.SavingList.length < valueData.Savings.length) {
      this.valueDetailsSaveButtonEnabled = valueData.Savings.splice(this.actionLog.SavingList.length).some(data => data.ERoiTypeId != null);
      this.valueDetailsDataChanged = true;
    } else {
      if (valueData.Savings.some((data, index) =>
        Object.keys(data).some((key) => data[key] != this.actionLog.SavingList[index][key]))) {
        this.valueDetailsSaveButtonEnabled = true;
        this.valueDetailsDataChanged = true;
      }
    }
    this.isChanged.emit(this.valueDetailsDataChanged);
    this.actionLogService.changeDirty(this.valueDetailsDataChanged);
  }

  eroiCategoryChanged(savingIndex, isEditMode = false) {
    const selectedSavingRowControl = this.savings.controls[savingIndex] as UntypedFormGroup;
    const selectedEroiTypeId = +selectedSavingRowControl.get('ERoiTypeId').value;
    const eroiTypeData = this.eroiTypes.find(data => data.ERoiTypeId === selectedEroiTypeId);
    const eroiTypeUnitMappings = this.displayUnitSymbols.filter(d => d.eroiTypeId === eroiTypeData.ERoiTypeId);
    this.selectedEroi[savingIndex] = { ...eroiTypeData, eroiUnitMappings: eroiTypeUnitMappings };
    Object.keys(selectedSavingRowControl.controls).forEach(field => {
      const control = selectedSavingRowControl.get(field);
      if ((field === 'AmountInDisplayUnit' || field === 'DisplayUnit') && !this.selectedEroi[savingIndex].IsAmountEnabled) {
        control.setValue(null);
        control.disable();
      } else {
        if ((field === 'MonetaryAmountInDisplayUnit' || field === 'CategoryTypeId')) {
          control.setValidators([Validators.required]);
        }
        if (field === 'DisplayUnit' && !isEditMode) {
          control.setValue(`${this.selectedEroi[savingIndex].DimensionalUnits[0].NalcoNumericsUnitOrSpecies}.${this.defaultUserPreference.UnitsOfMeasure == 0
            ? this.selectedEroi[savingIndex].DimensionalUnits[0].DefaultSubUnitUS
            : this.selectedEroi[savingIndex].DimensionalUnits[0].DefaultSubUnitMetric}`);
        }
        control.enable();
      }
      control.updateValueAndValidity({ emitEvent: false });
    });

    this.valueDetailsForm.get('AccountDetails').setValidators([Validators.required]);
    this.valueDetailsForm.get('AccountDetails').updateValueAndValidity({ onlySelf: true });
    this.valueDetailsForm.get('DisplayCurrency').setValidators([Validators.required]);
    this.valueDetailsForm.get('DisplayCurrency').updateValueAndValidity({ onlySelf: true });
  }

  ngOnChanges() {
    if (this.mode === 'edit') {
      this.getAccountDetailsBySiteId(this.actionLog.SiteId);
      this.assetDetailsForm.controls['createdDate'].setValue(this.actionLog.RecommendedOn);
      this.assetDetailsForm.controls['impact'].setValue(this.actionLog.ActionImpactTypeName);
      this.assetDetailsForm.controls['actionStatus'].setValue(this.actionLog.ActionStatusTypeName);
      this.assetDetailsForm.controls['Problem'].setValue(this.actionLog.Problem);
      this.assetDetailsForm.controls['RecommendedAction'].setValue(this.actionLog.Recommendation);
      this.assetDetailsForm.controls['assetName'].setValue(this.actionLog.ContextPointName);
      this.assetDetailsForm.controls['responsiblePerson'].setValue(this.actionLog.ResponsiblePerson.EmailAddress);
      if (this.actionLog.SavingList && this.actionLog.SavingList.length) {
        this.valueDetailsForm.get('AccountDetails').setValue(this.actionLog.AccountContextPointId);
        this.valueDetailsForm.get('DisplayCurrency').setValue(this.actionLog.SavingList[0].DisplayCurrency);
        this.savings.removeAt(0);
        this.actionLog.SavingList.forEach((saving, savingIndex) => {
          this.addSavingRow(true, saving);
          this.disableUnitAmountField(savingIndex);
        });
      }
    }
    /* if(this.actionLog.ActionStatusTypeName === "Closed" || this.actionLog.ActionStatusTypeName === "Deferred"){
      this.assetDetailsForm.get('ERoiTypeId').disable();
      this.isFieldsEnabled = false;
    }else{
      this.isFieldsEnabled = true;
    } */
  }

  public getIconClass(color: string): string {
    switch (color && color.toLowerCase()) {
      case 'red':
        return 'iconwater-HighRiskRed';
      case 'yellow':
        return 'iconwater-MediumRiskYellow';
      case 'green':
        return 'iconwater-LowRiskGreen';
      case 'grey':
        return 'iconwater-NoDataGrey';
      default:
        return '';
    }
  }

  addNewdata() {
    if (this.isExpand == 'add') {
      this.actionDesc.setValue(null);
      this.actionTakenDate.setValue(new Date());
      this.subGridUserIsPresent = false;
      this.isExpand = 'minus';
      this.tog = true;
      this.subGridUserEmail.reset(' ');
    } else {
      this.isExpand = 'add';
      this.tog = false;
    }
  }

  cancel() {
    if (this.isExpand == 'minus') {
      this.isExpand = 'add';
      this.tog = false;
    }
    if (!this.actionDetailsDataChanged) {
      this.isChanged.emit(false);
      this.actionLogService.changeDirty(false);
    }
  }

  createForm() {
    this.assetDetailsForm = this.fb.group({
      Problem: ['', Validators.required],
      RecommendedAction: '',
      actionStatus: ['', Validators.required],
      impact: ['', Validators.required],
      createdDate: new UntypedFormControl('', Validators.required),
      expectedResDate: new UntypedFormControl(''),
      responsiblePerson: ['', Validators.required],
      assetName: ['', Validators.required],
      NotifyEmail: '',
      ccPersons: '',
    }
      ,
      { validator: this.customDateValidation('createdDate', 'expectedResDate') }
    );
    this.valueDetailsForm = this.fb.group({
      DisplayCurrency: [this.defaultUserPreference.Currency.CurrencyCodeId],
      AccountDetails: [null],
      Savings: this.fb.array([])
    });
    this.addSavingRow();
  }

  get savings(): UntypedFormArray {
    return this.valueDetailsForm.get("Savings") as UntypedFormArray;
  }
  addSavingRow(isEditMode = false, saving?: Savings) {
    if (isEditMode) {
      this.savings.push(this.fb.group({
        ERoiTypeId: [saving.ERoiTypeId],
        MonetaryAmountInDisplayUnit: [saving.MonetaryAmountInDisplayUnit],
        AmountInDisplayUnit: [saving.AmountInDisplayUnit],
        DisplayUnit: [saving.DisplayUnit],
        CategoryTypeId: [saving.CategoryTypeId]
      }));
      this.eroiCategoryChanged(this.savings.length - 1, !!saving.AmountInDisplayUnit);
    } else {
      this.savings.push(this.fb.group({
        ERoiTypeId: null,
        MonetaryAmountInDisplayUnit: { value: null, disabled: true },
        AmountInDisplayUnit: { value: null, disabled: true },
        DisplayUnit: { value: null, disabled: true },
        CategoryTypeId: { value: null, disabled: true }
      }));
    }
  }

  removeSavingRow(savingIndex: number) {
    this.savings.removeAt(savingIndex);
    this.selectedEroi.splice(savingIndex, 1);
    if (this.selectedEroi.length === 0 || this.selectedEroi.indexOf(undefined) > -1) {
      this.valueDetailsForm.get('AccountDetails').clearValidators();
      this.valueDetailsForm.get('DisplayCurrency').clearValidators();
      this.valueDetailsForm.get('AccountDetails').updateValueAndValidity();
      this.valueDetailsForm.get('DisplayCurrency').updateValueAndValidity();
    }
    if (this.savings.length === 0) {
      this.addSavingRow();
    }
    this.valueDetailsForm.markAsDirty();
  }

  public dateOfActionChanged(value) {
    this.actionTakenDate.setValue(value);
    this.isChanged.emit(true);
    this.actionLogService.changeDirty(true);

  }

  public onStateChange($event) {

  }

  public subGridSaveHandler(data) {
    this.saveSubGrid(data);
    if (this.mode === 'edit') {
      for (let a of this.actionLog.ActionsTaken) {
        if (a['newlyAdded'] === "true" && this.parentUser.EmailAddress !== "") {
          this.isActionTakenPresent = true;
          this.assetDetailsForm.markAsDirty();
        }
      }
      this.isChanged.emit(true);
      this.actionLogService.changeDirty(true);
    }
    this.addNewdata()
  }

  public saveSubGrid(data: any) {
    this.reset();
    const dataObj: ActionsTaken = {
      'ActionTakenBy': this.subgridUser,
      'ActionTakenId': null,
      'RecommendationId': null,
      'ActionDescription': this.actionDesc.value, //data.ActionDescription,
      'ActionTakenById': this.subgridUser.UserId,
      'ActionTakenOn': moment.utc(this.intl.formatDate(this.actionTakenDate.value, "yyyy-MM-dd")).format('YYYY-MM-DDTHH:mm:ss'),
      'ModifiedBy': this.userSettings.UserId,
      'ModifiedOn': moment.utc(this.intl.formatDate(new Date(), "yyyy-MM-dd")).format('YYYY-MM-DDTHH:mm:ss'),
      'SourceApplicationTypeId': SourceApplicationTypeId.ECOLAB3D
    }
    this.actionLog.ActionsTaken.splice(0, 0, dataObj);
    if (this.mode === 'edit') {
      this.actionLog.ActionsTaken[0] = Object.assign(this.actionLog.ActionsTaken[0], {
        newlyAdded: 'true'
      })
    }
  }

  private reset() {
    this.data = [];
  }

  public onFileSelect(ev: SelectEvent): void {
    ev.files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        if (file.validationErrors === undefined) {
          const reader = new FileReader();
          this.newAttachment.RecommendationId = this.actionLog.RecommendationId;
          this.newAttachment.AttachmentName = file.name;
          this.newAttachment.ModifiedBy = this.userSettings.UserId;
          this.newAttachment.ModifiedOn = moment.utc(this.intl.formatDate(new Date(), "yyyy-MM-dd")).format('YYYY-MM-DDTHH:mm:ss');
          this.newAttachment.SourceApplicationTypeId = SourceApplicationTypeId.ECOLAB3D;
          this.newAttachment.StoragePath = '';
          // reader.onloadend = (e) => {
          //   const bytes = new Uint8Array(reader.result as Uint8Array);
          //   this.newAttachment.FileContent = Array.from(bytes);
          //   this.actionDetailsDataChanged = true;
          // };
          reader.onloadend = (e) => {
            const bytes = new Uint8Array(reader.result as ArrayBuffer);
            this.newAttachment.FileContent = this.arrayBufferToBase64(bytes);
            this.actionDetailsDataChanged = true;
          };
          reader.readAsArrayBuffer(file.rawFile);
        }
      }
      setTimeout(() => {
        const msg = this.elem.nativeElement.querySelectorAll('.k-file-validation-message');
        if (msg[0]) {
          msg[0].setAttribute('title', msg[0].innerText);
        }
      }, 1000);
    });
    this.isChanged.emit(true);
    this.actionDetailsDataChanged = true
    this.actionLogService.changeDirty(true);
  }

  // Helper function to convert ArrayBuffer to base64
  arrayBufferToBase64(buffer: Uint8Array): string {
    let binary = '';
    const len = buffer.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(buffer[i]);
    }
    return window.btoa(binary);
  }

  public onFileRemove(ev: RemoveEvent): void {
    this.newAttachment = new FileAttachments();
    // ev.files.forEach((file: FileInfo) => {
    //   this.actionLog.FileAttachments = this.actionLog.FileAttachments.filter(f => f.FileAttachmentId !== 0);
    // });
  }

  public downloadFile(f: any) {
    this.actionLogService.getFileAttachmentLink(f.FileAttachmentId).subscribe(
      response => {
        if (response && response.length) {
          window.open(response, "_blank");
        }
      },
      error => {
        // Failed to download error message or popup to be shown
        this.isError = true;
        this.errorInfo = error;
        // this.tog = false;
        if (500 <= error.status && error.status < 600) {
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
        this.setState(ComponentState.error);
      }
    );
  }

  cancelRowChanges(closeEditor: boolean) {
    this.cancelChanges.emit({ 'closeEditor': closeEditor, 'mode': this.mode });
  }

  // remove the newly added action taken only if user Clicks on 'ok' in Cancel pop-up
  confirmationOnCancelPopUp() {
    for (var i = this.actionLog.ActionsTaken.length - 1; i >= 0; --i) {
      if (this.actionLog.ActionsTaken[i]['newlyAdded'] === "true") {
        this.actionLog.ActionsTaken.splice(i, 1);
      }
    }
  }

  saveChanges(isSaved: boolean) {
    let confirmChanges = false;
    this.assetDetailsForm.markAsPristine(); // Bug 111127
    // map correct data for save payload
    if (this.newAttachment && this.newAttachment.FileContent) {
      this.actionLog.FileAttachments.push(this.newAttachment);
    }
    if (this.mode === 'add') {
      this.actionLog.RecommendationId = null;
      this.actionLog.Problem = this.assetDetailsForm.value.Problem;
      this.actionLog.ActionStatusTypeName = this.assetDetailsForm.value.actionStatus;
      this.selectedActionStatus = this.actionLogService.getObjInCollOnValue(this.actionStatusColl, this.actionLog.ActionStatusTypeName);
      this.actionLog.ActionStatusTypeId = this.selectedActionStatus[0].id;
      this.actionLog.ActionImpactTypeName = this.assetDetailsForm.value.impact;
      this.selectedImpactStatus = this.actionLogService.getObjInCollOnValue(this.impactStatusColl, this.actionLog.ActionImpactTypeName);
      this.actionLog.ActionImpactTypeId = this.selectedImpactStatus[0].id;
      this.actionLog.RecommendedOn = moment.utc(this.intl.formatDate(this.assetDetailsForm.value.createdDate, "yyyy-MM-dd")).format('YYYY-MM-DDTHH:mm:ss');
      this.actionLog.ExpectedResolutionDate = this.assetDetailsForm.value.expectedResDate ? moment.utc(this.intl.formatDate(this.assetDetailsForm.value.expectedResDate, "yyyy-MM-dd")).format('YYYY-MM-DDTHH:mm:ss') : null;
      this.actionLog.CreatedBy = this.userSettings.UserId;
    }
    if (this.mode === 'edit') {
      this.actionStatusType = this.actionLog.ActionStatusTypeName;
      this.actionLog.ActionStatusTypeName = this.assetDetailsForm.value.actionStatus;
      this.selectedActionStatus = this.actionLogService.getObjInCollOnValue(this.actionStatusColl, this.actionLog.ActionStatusTypeName);
      this.actionLog.ActionStatusTypeId = this.selectedActionStatus[0].id;
      this.actionLog.ActionImpactTypeName = this.assetDetailsForm.value.impact;
      this.selectedImpactStatus = this.actionLogService.getObjInCollOnValue(this.impactStatusColl, this.actionLog.ActionImpactTypeName);
      this.actionLog.ActionImpactTypeId = this.selectedImpactStatus[0].id;
      this.impactType = this.actionLog.ActionImpactTypeName;
      this.recommendationText = this.actionLog.Recommendation;
      this.actionLog.RecommendedOn = this.formatDateWithTimeZero(this.assetDetailsForm.value.createdDate);
      this.actionLog.ExpectedResolutionDate = this.assetDetailsForm.value.expectedResDate ? this.formatDateWithTimeZero(this.assetDetailsForm.value.expectedResDate) : null;
    }
    if (this.assetDetailsForm.value.actionStatus === '') {
      this.actionLog.ActionStatusTypeName = this.actionStatusType;
    } else {
      this.actionLog.ActionStatusTypeName = this.assetDetailsForm.value.actionStatus
    }
    this.actionLog.Recommendation = this.assetDetailsForm.value.RecommendedAction;
    if (this.assetDetailsForm.value.impact === '') {
      this.actionLog.ActionImpactTypeName = this.impactType;
    } else {
      this.actionLog.ActionImpactTypeName = this.assetDetailsForm.value.impact;
    }
    this.actionLog.IsSavingAvailable = this.valueDetailsSaveButtonEnabled && this.actionLog.SavingList.length != 0;
    if (this.savings.length > 0) {
      this.actionLog.AccountContextPointId = +this.valueDetailsForm.value.AccountDetails;
      let savings = [];
      this.savings.controls.filter(control => control.value.ERoiTypeId).forEach(control => {
        let saving = new Savings({
          ERoiTypeId: +control.value.ERoiTypeId,
          MonetaryAmountInDisplayUnit: control.value.MonetaryAmountInDisplayUnit,
          DisplayCurrency: this.valueDetailsForm.value.DisplayCurrency,
          CategoryTypeId: +control.value.CategoryTypeId,
          AmountInDisplayUnit: +control.value.AmountInDisplayUnit || null,
          DisplayUnit: control.value.DisplayUnit || ''
        });
        savings.push(saving);
      });
      let selectedEroiTypeId = [];
      const filterDisplayUnitSymbols = this.eroiTypes.filter((element: EroiTypes) => element.DimensionalUnits.length > 1 &&
        element.DimensionalUnits.some((unit: DimensionalUnit) => unit.NalcoNumericsUnitOrSpecies === 'Scalar')
      );
      filterDisplayUnitSymbols.forEach((element: EroiTypes) => {
        selectedEroiTypeId.push(element.ERoiTypeId)
      })
      let controlHavingScalar = false;
      const controls = this.savings.controls.filter(control => selectedEroiTypeId.includes(Number(control?.value?.ERoiTypeId)));
      controls.forEach((element) => {
        if (element.value.DisplayUnit === 'Scalar.None') {
          controlHavingScalar = true;
        }
      })
      if (controlHavingScalar) {
        confirmChanges = this.showModal(isSaved);
      }
      else {
        this.saveActionChanges(isSaved);
      }
      this.actionLog.SavingList = savings;
    }
    if (!this.actionLog.SavingList || this.actionLog.SavingList.length === 0) {
      this.actionLog.SavingList = null;
      this.actionLog.AccountContextPointId = null;
    }

    if (this.parentUser) {
      this.actionLog.ResponsiblePerson = this.parentUser;
      this.actionLog.ResponsiblePersonId = this.parentUser.UserId;
    }
    // this.actionLog.Recommendation = this.assetDetailsForm.value.RecommendedAction;
    this.actionLog.ModifiedBy = this.userSettings.UserId;
    this.actionLog.ModifiedOn = moment.utc(this.intl.formatDate(new Date(), "yyyy-MM-dd")).format('YYYY-MM-DDTHH:mm:ss');
    let ccUsersList = [];
    if (this.assetDetailsForm.value.ccPersons) {
      this.assetDetailsForm.value.ccPersons.forEach((user) => {
        let CcUser = {
          UserId: user.UserId,
          FirstName: user.FirstName,
          LastName: user.LastName,
          EmailAddress: user.EmailAddress,
          UserTypeId: user.UserType
        };
        ccUsersList.push({ NotificationRecipient: CcUser });
      })
    }
    this.actionLog.RecommendationNotificationRecipientsList = ccUsersList || [];
    if (this.isChecked == true) {
      this.actionLog.EmailSentDate = moment.utc(this.intl.formatDate(new Date(), "yyyy-MM-dd")).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      this.actionLog.EmailSentDate = null;
    }
    if (this.hostView == 'inventory') {
      this.assetDetailsForm.disable();
    }
    this.localCacheService.storeData('actionDetailsActiveTab', this.activeTabId);
  }

  /**
  * @description to save the edit or add changes
  * @param isSaved is to save the current changes
  * @returns no value
  */
  saveActionChanges(isSaved: boolean) {
    if (this.actionLog.SavingList && this.actionLog.SavingList.length > 0) {
      this.actionLog.SavingList.forEach((savings) => {
        if (savings.DisplayUnit === 'Scalar.None') {
          savings.AmountInDisplayUnit = null;
        }
      })
    }
    this.changesSaved.emit({ 'isSaved': isSaved, 'mode': this.mode, 'widgetId': this.widgetId, 'data': this.actionLog });
  }

  // dropdown for site and asset
  fetchMore() {
    this.pageIndex++;
    this.getSites(this.siteSearchText, true, this.pageIndex);
  }

  filterSiteText(event) {
    if (event.length > 2) {
      this.txtQueryChanged.next(event);
    }
  }

  getSites(searchString: string, isSearchedText?: boolean, pageIndex?: number) {
    if (pageIndex === undefined || pageIndex === null) {
      pageIndex = 1;
      this.pageIndex = 1;
    }
    const data = {
      'Dag': 'All',
      'PageIndex': pageIndex,
      'PageSize': 50,
      'SearchString': searchString
    };
    if (pageIndex === 1) {
      this.SiteDropdownData = [];
      this.siteData = [];
    }
    this.sitesLoading = true;
    this.actionLogService.getsites(data).subscribe(
      response => {
        this.siteData = JSON.parse(JSON.stringify(response));
        for (let i = 0; i < this.siteData.length; i++) {
          let displayName = '';
          if (this.siteData[i].Street) {
            displayName = this.siteData[i].SiteName + ' - ' +
              this.siteData[i].CustomerLocation + ' - ' + this.siteData[i].Street;
          } else {
            displayName = this.siteData[i].SiteName + ' - ' +
              this.siteData[i].CustomerLocation;
          }
          this.SiteDropdownData.push({ id: this.siteData[i].ContextPointId, display: displayName.toUpperCase() });
        }
        this.SiteDropdownData.sort(function (a, b) {
          const x = a.display;
          const y = b.display;
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
        if (isSearchedText) {
          this.selectedSite();
        }
        this.sitesLoading = false;
      },
      error => {
        this.isError = true;
        this.errorInfo = error;
        // this.tog = false;
        if (500 <= error.status && error.status < 600) {
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
        this.setState(ComponentState.error);
        this.sitesLoading = false;
      }
    );
  }

  loadAsset(event) {
    this.assetDataDropdown = [];
    this.assetCategoryData = [];
    this.valueDetailsForm.controls['AccountDetails'].setValue(null, { emitEvent: false });
    if (event !== undefined) {
      this.assetDetailsForm.get('NotifyEmail').enable();
      this.isDisable = false;
      this.onSiteSelection(event);
      if (this.duration) {
        this.getAssetData(this.duration, event);
      } else {
        this.getAssetData(0, event, this.customStartDate.toString(), this.customEndDate.toString());
      }
      this.getAccountDetailsBySiteId(event);
    } else {
      this.assetDetailsForm.get('NotifyEmail').disable();
      this.isDisable = true;
      this.selectedSiteDetails = [];
      this.selectedAssetDetails = [];
      this.assetDetailsForm.controls['responsiblePerson'].setValue('');
      this.assetDetailsForm.controls['assetName'].setErrors({ 'incorrect': true });
      this.accountDetails = [];
    }
  }

  loadAssetsForSiteView(event) {
    this.assetDataDropdown = [];
    this.assetCategoryData = [];
    this.valueDetailsForm.controls['AccountDetails'].setValue(null, { emitEvent: false });
    if (event !== undefined) {
      this.isDisable = false;
      if (this.duration) {
        this.getAssetData(this.duration, event);
      } else {
        this.getAssetData(0, event, this.customStartDate.toString(), this.customEndDate.toString());
      }
      this.getAccountDetailsBySiteId(event);
    } else {
      this.isDisable = true;
      this.accountDetails = [];
    }
  }

  getAssetData(duration, siteId, startDate?: string, endDate?: string) {
    this.assetsLoading = true;
    this.assetData = [];
    let tempDateValues = this.actionLogService.updateDateValues;
    if (this.actionLogService.currentSwitchValue == true ||
      (tempDateValues !== undefined && (tempDateValues.isCustomDateSelected == true
        || tempDateValues.date.DateRange == '10'))) {
      duration = 0;
      let sDate, eDate;
      if (tempDateValues && tempDateValues.filterCriteriaObj) {
        sDate = tempDateValues.filterCriteriaObj.startDate;
        eDate = tempDateValues.filterCriteriaObj.endDate;
      }
      startDate = sDate ? sDate : this.filterCriteria.startDate;
      endDate = eDate ? eDate : this.filterCriteria.endDate;
    } else if (this.actionLogService.currentSwitchValue == false && tempDateValues !== undefined
      && tempDateValues.isCustomDateSelected == false) {
      duration = tempDateValues.date.Number ? tempDateValues.date.Number : duration;
    }
    const timeLine = duration == undefined ? 0 : duration;
    this.actionLogService.getAssets(timeLine, siteId, startDate, endDate).subscribe(
      response => {
        this.systemData = JSON.parse(JSON.stringify(response));
        this.getAssets();
        this.assetsLoading = false;
      },
      error => {
        this.isError = true;
        this.errorInfo = error;
        // this.tog = false;
        if (500 <= error.status && error.status < 600) {
        } else if (error.status === 417) {
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
        this.setState(ComponentState.error);
        this.assetsLoading = false;
      }
    );
  }


  onSiteSelection(event) {
    this.selectedSiteOnInit = [];
    this.selectedAssetDetails = [];
    this.assetDetailsForm.controls['assetName'].setErrors({ 'incorrect': true });
    if (event === undefined) {
      this.selectedSiteforAvailParams = '';
    } else {
      let index = this.siteData.findIndex(x => x.ContextPointId === event);
      let selectedSite = this.siteData[index];
      this.selectedSiteName = selectedSite.SiteName;
      index = this.SiteDropdownData.findIndex(x => x.id === event);

      this.selectedSiteDetails = {
        SiteName: selectedSite.SiteName,
        Street: selectedSite.Street,
        SiteLocation: selectedSite.CustomerLocation,
        SiteID: selectedSite.ContextPointId
      };
      this.actionLog.SiteId = this.selectedSiteDetails.SiteID;
      this.actionLog.SiteName = this.selectedSiteDetails.SiteName;
      this.selectedSiteforAvailParams = this.SiteDropdownData[index].display;
      this.isChanged.emit(true);
      this.actionLogService.changeDirty(true);
    }
  }

  selectedSite() {
    // this.selectedAssetOnInit = [];
  }

  getAssets(id?: number) {
    this.assetData = [];
    this.assetDataDropdown = [];
    this.assetCategoryData = [];
    this.assetData = this.systemData;
    this.assetData = this.uniqueArray(this.assetData, 'AssetId');
    this.assetData.forEach(item => {
      const assetDropdownItem = {
        id: item.AssetId,
        display: item.AssetName,
        checked: false,
        category: item.AssetSubTypeName
      };
      if (this.hostView === 'systemView' && this.assetIdList.length > 0) {
        if (this.assetIdList.includes(item.AssetId)) {
          this.assetDataDropdown.push(assetDropdownItem);
        }
      } else {
        this.assetDataDropdown.push(assetDropdownItem);
      }
    });
    this.assetDataDropdown.sort(function (a, b) {
      const x = a.display.toUpperCase();
      const y = b.display.toUpperCase();
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });

    this.assetData.forEach(item => {
      // [Otimizations] -- for loop not needed
      for (let i = 0; i < this.assetData.length; i++) {
        this.assetCategoryData.push({ value: i, text: this.assetData[i].AssetSubTypeName, checked: false });
      }
    });
    this.assetCategoryData = this.uniqueArray(this.assetCategoryData, 'text');
  }

  onAssetSelection(event) {
    this.selectedAssetOnInit = [];
    if (this.accountDetails.length !== 1) {
      this.valueDetailsForm.controls['AccountDetails'].setValue(null, { emitEvent: false });
    }
    if (event !== undefined) {
      const index = this.assetData.findIndex(x => x.AssetId === event);
      this.selectedAssetDetails = this.assetData[index];

      if (this.selectedAssetDetails) {
        this.selectedAssetName = this.selectedAssetDetails.AssetName;
        this.actionLog.ContextPointName = this.selectedAssetDetails.AssetName;
        this.actionLog.ContextPointId = this.selectedAssetDetails.AssetId;
        this.actionLog.ContextPointStatus = this.selectedAssetDetails.AssetPerformanceColor;
        this.actionLog.ContextPointTypeId = null;
        if (this.selectedAssetDetails.AssociatedSoldto !== '') {
          this.valueDetailsForm.controls['AccountDetails'].setValue(this.getSelectedAssetStatus(this.selectedAssetDetails), { emitEvent: false });
        }
      }
    } else {
      this.selectedAssetDetails = [];
      this.assetDetailsForm.controls['assetName'].setErrors({ 'incorrect': true });
    }
  }

  selectedAsset() {

  }

  uniqueArray(array, key) {
    const result = [];
    const map = new Map();
    for (const item of array) {
      if (!map.has(item[key])) {
        map.set(item[key], true);
        result.push(item);
      }
    }
    return result;
  }

  // Responsible person search
  searchfilterChange(e: any, type: any) {
    this.filterupdated.next({ value: e, type: type });
    if (e == "") {
      this.assetDetailsForm.invalid;
    }
    this.isChanged.emit(true);
    this.actionLogService.changeDirty(true);
  }

  searchValueChange(e: any, type: string) {
    const user: ActionLogUser = this.usersList.find((s) => s.EmailAddress.toLowerCase() === e.toLowerCase());
    if (user !== undefined) {
      if (type === 'parent') {
        this.parentUser = user;
        if (this.actionLog.ResponsiblePersonId !== this.parentUser.UserId) {
          if (this.mode === 'add') {
            this.assetDetailsForm.markAsDirty();
          } else if (this.mode === 'edit') {
            this.checkNewlyAddedAction();
          }
        } else if (this.actionLog.ResponsiblePersonId === this.parentUser.UserId) {
          if (this.mode === 'edit') {
            this.checkNewlyAddedAction();
          }
        }
      } else if (type === 'subgrid') {
        this.subgridUser = user;
        //Validations for Add button of Action Taken Field
        this.subGridUserIsPresent = true;
        this.validateAddButton();
      }
    } else {
      if (type === 'parent') {
        this.parentUser = new ActionLogUser();
        this.assetDetailsForm.controls['responsiblePerson'].setValue('');
      } else if (type === 'subgrid') {
        this.subGridUserIsPresent = false;
        this.subGridUserEmail.reset('');
        this.validateAddButton();
      }
      if (this.mode === 'edit') {
        //this.assetDetailsForm.markAsPristine();
      }
    }
  }

  getUserSearchForUserView(searchText: string, type: string) {
    this.usersList = [];
    if (type === 'subgrid') {
      this.loadingUserNameSubgrid = true;
      this.loadingUserName = false;
    } else {
      this.loadingUserName = true;
      this.loadingUserNameSubgrid = false;
    }
    let siteIdForPerson;
    if (this.hostView === 'siteView' || this.hostView === 'inventory' || (this.hostView === 'SSD' && this.isSiteView) || this.hostView === 'systemView') {
      siteIdForPerson = this.contextPointData.siteId
    } else if ((this.hostView === 'actionlog' && this.filterCriteria.siteName == '') || (this.hostView === 'SSD' && !this.isSiteView)) {
      siteIdForPerson = this.actionLog.SiteId;
    } else if (this.hostView === 'actionlog' && this.filterCriteria.siteName !== '') {
      siteIdForPerson = this.filterCriteria.siteId;
    }
    this.actionLogService.getResponsiblePerson(siteIdForPerson, searchText).subscribe(response => {
      if (response && response.length) {
        response.forEach(user => {
          this.usersList.push(new ActionLogUser(user));
        });
      }
      this.loadingUserName = false;
      this.loadingUserNameSubgrid = false;
    }, error => {
      this.isError = true;
      this.errorInfo = error;
      // this.tog = false;
      if (500 <= error.status && error.status < 600) {
      } else if (error.status === 417) {
        // this.router.navigate(['/access-denied']);
      } else if (error.status === 404) {
        // this.router.navigate(['/**']);
      } else {
        // this.errorHandling.showErrorPopup(error);
      }
      this.setState(ComponentState.error);
    });
  }

  checkNewlyAddedAction() {
    for (let a of this.actionLog.ActionsTaken) {
      if (a['newlyAdded'] === "true" && this.parentUser.EmailAddress !== "") {
        this.isActionTakenPresent = true;
        this.assetDetailsForm.markAsDirty();
      }
    }
  }

  //Validation for Add button of Action Taken field to be Enabled or Disabled
  validateAddButton() {
    if (this.actionDesc.value === null || this.actionDesc.value === "" || this.subGridUserIsPresent === false) {
      this.disableAdd = true;
    } else {
      this.disableAdd = false;
    }
  }

  isNotifyChecked(e) {
    if (e.target.checked === true) {
      this.validateAddButton();
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
  }


  customDateValidation(recDt, expDt): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      if (this.mode === 'add') {
        const recDate = group.controls[recDt];
        const expDate = group.controls[expDt];
        if (recDate.value !== "" && expDate.value !== "" && moment(expDate.value).format('YYYY-MM-DD') < moment(recDate.value).format('YYYY-MM-DD')) {
          return { 'dates': true };
        }
      }
      else if (this.mode === 'edit') {
        const recDate = moment(this.actionLog.RecommendedOn).format('YYYY-MM-DD');
        const expDate = group.controls[expDt].value !== "" ? moment(group.controls[expDt].value).format('YYYY-MM-DD') : null;
        if (expDate < recDate) {
          return { 'dates': true };
        }
      }
      return null;
    }
  }

  private openedFromEdata() {
    this.actionLog.SiteId = this.contextPointData.siteId;
    this.actionLog.SiteName = this.contextPointData.siteName;
    this.actionLog.ContextPointName = this.contextPointData.assetName;
    this.actionLog.ContextPointId = this.contextPointData.assetId;
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscription.unsubscribe();
    this.ccsubscription.unsubscribe();
  }

  getUserSearchForCcUserView(searchText: string, type: string) {
    this.listItems = [];
    if (type === 'subgrid') {
      this.loadingUserNameSubgrid = true;
      this.loadingCcUserName = false;
    } else {
      this.loadingCcUserName = true;
      this.loadingUserNameSubgrid = false;
    }
    let siteIdForPerson;
    if (this.hostView === 'siteView' || this.hostView === 'inventory' || (this.hostView === 'SSD' && this.isSiteView) || this.hostView === 'systemView') {
      siteIdForPerson = this.contextPointData.siteId
    } else if ((this.hostView === 'actionlog' && this.filterCriteria.siteName == '') || (this.hostView === 'SSD' && !this.isSiteView)) {
      siteIdForPerson = this.actionLog.SiteId;
    } else if (this.hostView === 'actionlog' && this.filterCriteria.siteName !== '') {
      siteIdForPerson = this.filterCriteria.siteId;
    }
    this.actionLogService.getResponsiblePerson(siteIdForPerson, searchText).subscribe(response => {
      if (response && response.length) {
        response.forEach(user => {
          let index = -1;
          if (this.assetDetailsForm.value.ccPersons)
            index = this.assetDetailsForm.value.ccPersons.findIndex(person => person.UserId === user.UserId);
          if (index == -1)
            this.listItems.push(new ActionLogCCUser(user));
        });
      }
      this.loadingCcUserName = false;
      this.loadingUserNameSubgrid = false;
    }, error => {
      this.isError = true;
      this.errorInfo = error;
      // this.tog = false;
      if (500 <= error.status && error.status < 600) {
      } else if (error.status === 417) {
        // this.router.navigate(['/access-denied']);
      } else if (error.status === 404) {
        // this.router.navigate(['/**']);
      } else {
        // this.errorHandling.showErrorPopup(error);
      }
      this.setState(ComponentState.error);
    });
  }

  ccSearchfilterChange(e: any, type: any) {
    this.ccfilterupdated.next({ value: e, type: type });
    if (e == "") {
      this.assetDetailsForm.invalid;
    }
    this.isChanged.emit(true);
    this.actionLogService.changeDirty(true);
  }

  ccSearchValueChange(e, type: string) {
    const user: ActionLogCCUser = this.listItems.find((s) => s.EmailAddress.toLowerCase() === e[0]?.EmailAddress.toLowerCase());
    if (user !== undefined) {
      if (type === 'parent') {
        //this.parentUser = user;
        if (this.actionLog.ResponsiblePersonId !== this.parentUser.UserId) {
          if (this.mode === 'add') {
            this.assetDetailsForm.markAsDirty();
          } else if (this.mode === 'edit') {
            this.checkNewlyAddedAction();
          }
        } else if (this.actionLog.ResponsiblePersonId === this.parentUser.UserId) {
          if (this.mode === 'edit') {
            this.checkNewlyAddedAction();
          }
        }
      } else if (type === 'subgrid') {
        //this.subgridUser = user;
        //Validations for Add button of Action Taken Field
        this.subGridUserIsPresent = true;
        this.validateAddButton();
      }
    } else {
      if (type === 'parent') {
        //this.parentUser = new ActionLogCCUser();
      } else if (type === 'subgrid') {
        this.subGridUserIsPresent = false;
        this.validateAddButton();
      }
      if (this.mode === 'edit') {
        //this.assetDetailsForm.markAsPristine();
      }
    }
  }

  focus() {
    this.selectAllCheck = false;
    this.listItems = [];
    this.noDataFound = "No User Selected";
  }

  get getTotalSavings() {
    let totalSavings;
    this.savings.controls.forEach(control => {
      if (control.get('MonetaryAmountInDisplayUnit').value !== null) {
        totalSavings = (Number(totalSavings) || 0) + Number(control.get('MonetaryAmountInDisplayUnit').value);
      }
    });
    if (totalSavings == undefined) {
      return '';
    } else {
      const formatter = new Intl.NumberFormat("en-US", {
        notation: "compact",
        compactDisplay: "short",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      } as any);
      return formatter.format(totalSavings);
    }
  }

  isEroiCategorySelected(eroiTypeId): boolean {
    return this.selectedEroi.findIndex(eroi => eroi && eroi.ERoiTypeId === eroiTypeId) > -1;
  }

  tabChanged($event) {
    this.activeTabId = $event.nextId;
  }

  validateWholeNumbers(event, savingIndex) {
    var inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, '');
    if (inputValue.length >= 15) {
      inputValue = inputValue.slice(0, 15);
    }
    event.target.value = inputValue;
    this.savings.at(savingIndex).get('MonetaryAmountInDisplayUnit').setValue(event.target.value);
  }

  validateDecimalPrecision(event: any, savingIndex) {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    const parts = inputValue.split('.');
    const integerPart = parts[0];
    const decimalPart = parts[1];
    if (integerPart.length > 12) {
      parts[0] = integerPart.slice(0, 12);
    }
    if (decimalPart && decimalPart.length > 2) {
      parts[1] = decimalPart.slice(0, 2);
    }
    event.target.value = parts.join('.');
    this.savings.at(savingIndex).get('AmountInDisplayUnit').setValue(event.target.value);
  }

  getAccountDetailsBySiteId(siteId) {
    this.actionLogService.getSoldTosBySiteContextPointId(siteId).subscribe(
      response => {
        if (response && response.length) {
          this.accountDetails = response;
          if (this.accountDetails.length === 1) {
            this.valueDetailsForm.controls['AccountDetails'].setValue(this.accountDetails[0].ContextPointId, { emitEvent: false });
          } else {
            if (this.actionLog.ContextPointId && !this.actionLog.AccountContextPointId) {
              this.actionLogService.getAssets(0, siteId, this.filterCriteria.startDate, this.filterCriteria.endDate).subscribe(
                response => {
                  let assetStatusData = JSON.parse(JSON.stringify(response));
                  let selectedAssetDetails = assetStatusData.find(data => data.AssetId === this.actionLog.ContextPointId);
                  if (selectedAssetDetails && selectedAssetDetails.AssociatedSoldto !== '') {
                    this.valueDetailsForm.controls['AccountDetails'].setValue(this.getSelectedAssetStatus(selectedAssetDetails), { emitEvent: false });
                  }
                });
            }
            if (this.actionLog.AccountContextPointId && this.accountDetails.findIndex(detail => detail.ContextPointId === this.actionLog.AccountContextPointId) < 0) {
              this.valueDetailsForm.controls['AccountDetails'].setValue(null, { emitEvent: false });
            }
          }
          this.cdr.detectChanges();
        }
      });
  }

  getSelectedAssetStatus(selectedAssetDetails) {
    let account = this.accountDetails.find(detail => detail.ContextPointName === selectedAssetDetails.AssociatedSoldto);
    if (account) {
      return account.ContextPointId;
    }
    return null;
  }

  /**
   * @description to show the confirmation popup
   * @param isSaved is to save the current changes
   * @returns returns false.
   */
  private showModal(isSaved: boolean): boolean {
    this.modalRef = this.modalService.open(ConfirmationPopupComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'saveExit-modal'
    }).componentInstance;
    this.modalRef.alertIcon = false;
    this.modalRef.modcontent = this.translate.instant('ACTION_LOG.CONFIRMATION_MESSAGE');
    this.modalRef.localDriverBtn = false;
    this.modalRef.disablebtn = true;
    this.modalRef.closeAsCancel = true;
    this.modalRef.closeIcon = true;
    this.modalRef.actionBtn = this.translate.instant('COMMON.CONFIRM');
    this.modalRef.cancelModal.subscribe(($event) => {
      this.isSaveButtonEnabled = false;
      this.valueDetailsForm.valueChanges.subscribe(data => {
        if (data) {
          this.isChanged.emit(true);
          this.isSaveButtonEnabled = true;
        }
      })
      return false;
    });
    this.modalRef.submitModal.subscribe(($event) => {
      if ($event) {
        this.saveActionChanges(isSaved);
        return true;
      }
      this.modalRef.activeModal.close();
    });
    return false;
  }
  /**
 * @description to get value of sourceApplicationTypeId
 * @param value is an sourceApplicationTypeId
 * @returns return The name of the source application associated with the given TypeId.
 */
  public getSourceApplication(value: number): string {
    const source = value === SourceApplicationTypeId.AI ? this.translate.instant('ACTION_LOG.ARTIFICIAL_INTELLIGENCE') : this.actionLogService.getSourceApplicationType(value);
    return source;
  }

  /**
 * @description to disable UnitAmount field
 * @param savingIndex index of saving row
 * @returns no value
 */
  disableUnitAmountField(savingIndex: number): void {
    const selectedSavingRowControl = this.savings.controls[savingIndex] as UntypedFormGroup;
    const selectedEroiTypeId = +selectedSavingRowControl.get('ERoiTypeId').value;
    const eroiTypeData = this.eroiTypes.find((data: EroiTypes) => data.ERoiTypeId === selectedEroiTypeId);
    const unitSymbols = this.displayUnitSymbols.filter((d: EroiUnitMappings) => d.eroiTypeId === eroiTypeData.ERoiTypeId);
    if (selectedEroiTypeId && (selectedSavingRowControl.get('DisplayUnit').value === 'Scalar.None') && unitSymbols.length > 1) {
      selectedSavingRowControl.get('AmountInDisplayUnit').setValue(null);
      selectedSavingRowControl.get('AmountInDisplayUnit').disable();
    }
  }

  /**
* @description to enable UnitAmount field
* @param value eroiTypeId
*  @param saving fetch whole saving row data
* @returns no value
*/
  enableUnitAmountField(value: number, saving: any): void {
    const selectedEroiTypeId = saving.get('ERoiTypeId').value;
    if (selectedEroiTypeId && saving.get('DisplayUnit').value !== 'Scalar.None') {
      saving.get('AmountInDisplayUnit').enable();
    }
  }

  /**
* @description to format date and add timestamp
* @param date date
* @returns returns the date with timestamp
*/
  formatDateWithTimeZero(date: string): string {
    return moment(date).format('YYYY-MM-DD') + 'T00:00:00';
  }
}
