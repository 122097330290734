import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, map } from 'rxjs/operators';
import { USERROLES, VULNERABLE_CHAR } from '../../app.config';
import { AppEnvConfig } from '../../app.env.config';
import { UserService } from '../../core/user/user.service';
import { IStuiType } from '../../shared-main/contracts/IStuiType';
import { ContextPointType } from '../../shared/_enum/constants';
import * as contracts from '../../shared/contracts/_contracts';
import { UserSettings } from '../../shared/models/User';
import { IMeasurementType, IMeasurementTypes } from '../_contracts/IMeasurementType';
import { ISite } from '../_contracts/ISite';
import { AutoDatasourceSubType, CategoryType, DataSourceType, DeleteEngineItemType, MeasurementMappingDeleteType, Mode, UnitUsageType } from '../_enum/constants';
import * as model from '../_models/';
import { AccountDetails, MostUsefulMeasurement } from '../_models/';
import { assetPortMsrDtls, CalculationConfiguration, measurementInstanceCalculationMap } from '../_models/CalculationConfiguration';
import { SamplePointsAndMeasurements } from '../_models/TourConfiguration';
import { AssetHarvesterServiceConfig } from './asset-harvester.service.config';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'

@Injectable()
export class AssetHarvesterService {
  private configEnv: any;
  public url: string;
  public readOnly: boolean = false;
  public writeAlso: boolean = false;
  public assetHarvesterDecommissionWrite: boolean = false;
  public assetHarvesterAdmin: boolean = false;
  public tankAdmin: boolean = false;
  // public wfiSensorAdmin: boolean = false;
  public waterHarvesterCalculations: boolean = false;
  public dagSiteSystems: Array<model.ContextPointBase> = [];
  public dagSiteAreas: Array<model.ContextPointBase> = [];
  public dagSiteAssets: Array<model.ContextPointBase> = [];
  public systemMasterData: Array<model.Category> = [];
  public InventoryData: model.InventoryData = new model.InventoryData();
  public assetConfig: model.AssetConfig = new model.AssetConfig();
  public InventoryAssetData: Array<model.AssetConfig> = [];
  public assetCollection = {};
  public soldToShipToData: any;
  public categoryAssets;
  public measurementUnit: string = '';
  public assetsAccToCategory = [];

  usefulMsrs = [];
  ddMeasureType: Array<{ id: IMeasurementType, display: string, checked: boolean }>
  measureTypeList: IMeasurementTypes[] = [];
  edataMeasurementList = [];
  public editedObject: any = { assetCpId: 0, portCpId: 0, assetAcc: null, dataSource: '', dataSourceId: 0 };
  duplicatedAssetsList = []; //To display the new badge next to duplicated asset

  public currencyData;
  public siteCultureData = {};
  private isAssetEdited = new Subject<boolean>();
  $isAssetEdited = this.isAssetEdited.asObservable();

  private isSubMsEdited = new Subject<boolean>();
  isSubMsEdited$ = this.isSubMsEdited.asObservable();

  private isAddMsrNotSaved = new BehaviorSubject<boolean>(null);
  isAddMsrNotSaved$ = this.isAddMsrNotSaved.asObservable();

  private isReorderMsEdited = new Subject<boolean>();
  isReorderMsEdited$ = this.isReorderMsEdited.asObservable();

  private reloadAssetLevelMsr = new Subject<any>();
  reloadAssetLvlMsr$ = this.reloadAssetLevelMsr.asObservable();

  private setActiveACC = new Subject<any>();
  setActiveACC$ = this.setActiveACC.asObservable();

  private mostUsefulMeasurements = new Subject<any>();
  mostUsefulMeasurements$ = this.mostUsefulMeasurements.asObservable();

  private isReplicate = new BehaviorSubject<boolean>(false);
  isReplicate$ = this.isReplicate.asObservable();

  private requestPayload = new BehaviorSubject<any>(null);
  requestPayload$ = this.requestPayload.asObservable();

  private moveMsrRequestPayload = new BehaviorSubject<any>(null);
  moveMsrRequestPayload$ = this.moveMsrRequestPayload.asObservable();

  private isSelectedSiteUpdated = new BehaviorSubject<boolean>(null);
  isSelectedSiteUpdated$ = this.isSelectedSiteUpdated.asObservable();

  private cassadyMappedAssets = new BehaviorSubject<any>(null);
  cassadyMappedAssets$ = this.cassadyMappedAssets.asObservable();

  public assetsByCategory = [];
  public atbAssetProperties = [];
  public systemProperties = [];
  public newAssetCollection = {};
  public offlineConfig = [];
  public contextPointLimits = [];
  public masterSlaveList = [];
  public selectedAssetIndex: number;
  public assetCreatorType = '?assetCreatorType=AssetHarvester';
  public mappedMsr = [];
  public unMappedMsr = [];
  public assetConfiguration = [];
  public tankSerialNumbers = [];
  public soldToShipTo: Array<model.soldToShipTo> = [];
  public systems = [];
  public ahSystems;
  public selectedSystemIndex: number;
  public dagId: number;
  public categoryId: number;
  public siteDagChildren = [];
  public assetType = [];
  public measurementSourceOptions = [];
  public uomOptionsListByNalcounitname = {};
  public stuitypesListByStuiTypeId = {};
  private siteDagId = new Subject<any>();
  $siteDagId = this.siteDagId.asObservable();
  public siteLevelMeasurements: any = [];
  public assetByDagCpIdCategoryId: any = [];
  public assetLevelCalculations: any = [];
  public siteLevelMsrs: any = [];
  public getMeasurementCalcsByportCpId: any = [];
  public getAllMeasurementCalcsByMeasurementInstanceId: any = [];
  public assetLevelCalculationsVariableDetails: any = [];
  public assetHierarchy: any = [];
  public assetLevelMeasurements: any = [];
  public tourConfiguration: any = [];
  public configurationData: any = [];

  private hideAddButton = new Subject<boolean>();

  private sitesResponse: contracts.ISitesResponse;
  hideAddButton$ = this.hideAddButton.asObservable();
  public scaleGridEdit = false;
  public offlineAsset: any = {};

  public isInventoryAssetEdited = false;
  public tvdDetails: model.TvdDetails = new model.TvdDetails();
  public updatedTvdDetails: model.TvdDetails = new model.TvdDetails();

  public siteRes: contracts.ISitesResponse;

  public siteList: ISite[];

  flowUnits;
  volumeUnits;
  temperatureUnits;

  tvdMeasurements: { [siteCpId: number]: any[] } = {};

  private isDataSourceUpdated = new BehaviorSubject<boolean>(false);
  isDataSourceUpdated$ = this.isDataSourceUpdated.asObservable();
  private _siteCurrencySymbol: any;
  fileName: any = 'Export Data';
  exportData: any;
  csvoptions: any;
  invalidOpSchedule:boolean = false;
  inValidOfflineSch:boolean = false;
  set siteCurrencySymbol(value) {
    this._siteCurrencySymbol = value;
  }

  get siteCurrencySymbol() {
    return this._siteCurrencySymbol;
  }
  assetTvdConfigurations = [];


  setAddButtonStatus(flag: any) {
    this.hideAddButton.next(flag);
  }

  closeSourceModal(flag: boolean) {
    this.isReplicate.next(flag);
  }

  setAssets(value: any): void {
    this.cassadyMappedAssets.next(value);
  }

  getAssets(): any {
    this.cassadyMappedAssets.getValue();
  }

  getPayload(): any {
    return this.requestPayload.getValue();
  }

  setPayload(value: any): void {
    this.requestPayload.next(value);
  }

  getMoveMsrPayload(): any {
    return this.moveMsrRequestPayload.getValue();
  }

  setMoveMsrPayload(value: any): void {
    this.moveMsrRequestPayload.next(value);
  }

  private isEdited = new Subject<boolean>();
  isEdited$ = this.isEdited.asObservable();
  calculationConfig: CalculationConfiguration[];

  getEditStatus(isEdited: any) {
    this.isAssetEdited.next(isEdited);
  }

  private addOrEditClick = new Subject();
  addOrEditClick$ = this.addOrEditClick.asObservable();


  getSubConfigEditStatus(isEdited: any) {
    this.isSubMsEdited.next(isEdited);
  }

  getMsraddStatus(isEdited: any) {
    this.isAddMsrNotSaved.next(isEdited);
    this.scaleGridEdit = isEdited;
  }

  getReorderMeasurementsEditStatus(isEdited: any) {
    this.isReorderMsEdited.next(isEdited);
  }

  getSiteDagIDChanges(event) { //  This will update SiteDagId after Changing Site.
    this.siteDagId.next(event);
  }

  setActiveAcordions(activeAccObj: any) {
    this.setActiveACC.next(activeAccObj);
  }
  reloadAssetLevelMeasurements(assetCpId: any) {
    this.reloadAssetLevelMsr.next(assetCpId);
  }

  getMostUsefulMeasurements(msr: any) {
    this.mostUsefulMeasurements.next(msr);
  }

  setSelectedSiteUpdate(value: boolean) {
    this.isSelectedSiteUpdated.next(value);
  }

  private isOfflineSecEdited = new Subject<boolean>();
  $isOfflineSecEdited = this.isOfflineSecEdited.asObservable();

  getOfflineSecEditedStatus(isEdited: any) {
    this.isOfflineSecEdited.next(isEdited);
  }

  private isLimitTabEdited = new Subject<boolean>();
  $isLimitTabEdited = this.isLimitTabEdited.asObservable();

  getLimitTabEditStatus(isEdited: any) {
    this.isLimitTabEdited.next(isEdited);
  }
  private savedChanges = new Subject<any>();
  $savedChanges = this.savedChanges.asObservable();

  getSavedChanges(data: any) {
    this.savedChanges.next(data);
  }

  private assetTabDisabled = new Subject<any>();
  $assetTabDisabled = this.assetTabDisabled.asObservable();

  getAssetTabDisabled(data: any) {
    this.assetTabDisabled.next(data);
  }

  private limitTabDisabled = new Subject<any>();
  $limitTabDisabled = this.limitTabDisabled.asObservable();

  getLimitTabDisabled(data: any) {
    this.limitTabDisabled.next(data);
  }

  private invalidLedLimitTabDisabled = new Subject<any>();
  $invalidLedLimitTabDisabled = this.invalidLedLimitTabDisabled.asObservable();

  getInvalidLedLimitTabDisabled(data: any) { // Disable tabs keeping save enabled in case of invalid LED
    this.invalidLedLimitTabDisabled.next(data);
  }

  private isSchSecEdited = new Subject<boolean>();
  $isSchSecEdited = this.isSchSecEdited.asObservable();

  getSchSecEditedStatus(isEdited: any) {
    this.isSchSecEdited.next(isEdited);
  }

  private isNormalizeEdited = new Subject<boolean>();
  $isNormalizeEdited = this.isNormalizeEdited.asObservable();

  getNormalizeEditedStatus(isEdited: any) {
    this.isNormalizeEdited.next(isEdited);
  }

  private assetSource = new Subject<any>();
  masterAsset = this.assetSource.asObservable();

  openMasterRow(data: any) {
    this.assetSource.next(data)
  }

  private opSchTabDisabled = new Subject<any>();
  $opSchTabDisabled = this.opSchTabDisabled.asObservable();

  getOpSchTabDisabled(data: any) {
    this.opSchTabDisabled.next(data)
  }

  private normalizeTabDisabled = new Subject<any>();
  $normalizeTabDisabled = this.normalizeTabDisabled.asObservable();

  getnormalizeTabDisabled(data: any) {
    this.normalizeTabDisabled.next(data)
  }

  private tankTypeDetails = new Subject<any>();
  $tankTypeDetails = this.tankTypeDetails.asObservable();
  getTankTypeDetails(data: any) {
    this.tankTypeDetails.next(data);
  }

  private bypassSerialNumber = new Subject<any>();
  $bypassSerialNumber = this.bypassSerialNumber.asObservable();
  getBypassSerialNumber(data: any) {
    this.bypassSerialNumber.next(data);
  }

  private inventoryFields = new Subject<any>();
  $inventoryFields = this.inventoryFields.asObservable();
  getInventoryPayLoad() {
    this.inventoryFields.next();
  }

  private isAssetPropEdited = new Subject<boolean>();
  $isAssetPropEdited = this.isAssetPropEdited.asObservable();

  getAssetPropEditedStatus(isEdited: any) {
    this.isAssetPropEdited.next(isEdited);
  }

  private isAssetQuestionsDateError = new Subject<boolean>();
  $isAssetQuestionsDateError = this.isAssetQuestionsDateError.asObservable();

  getAssetQuestionsDateErrorStatus(isDateError: any) {
    this.isAssetQuestionsDateError.next(isDateError);
  }

  private inventoryEditTankType = new Subject<any>();
  $inventoryEditTankType = this.inventoryEditTankType.asObservable();
  editTankType(data: any) {
    this.inventoryEditTankType.next(data);
  }

  private isInventoryAssetEdit = new Subject<boolean>();
  $isInventoryAssetEdit = this.isInventoryAssetEdit.asObservable();
  getInventoryAssetEditStatus(isEdited: any) {
    this.isInventoryAssetEdit.next(isEdited);
  }

  private isInventoryTypeValid = new Subject<boolean>();
  $isInventoryTypeValid = this.isInventoryTypeValid.asObservable();
  getInventoryTypeValidStatus(isValid: any) {
    this.isInventoryTypeValid.next(isValid);
  }

  private getAssetMeasurementsData = new Subject<MostUsefulMeasurement>();
  $getAssetMeasurementsData = this.getAssetMeasurementsData.asObservable();

  setEditAHStatus(getAssetMeasurementsData: MostUsefulMeasurement) {
    this.getAssetMeasurementsData.next(getAssetMeasurementsData);
  }

  private dimensionsEdited = new BehaviorSubject<boolean>(false);
  $dimensionsEdited = this.inventoryFields.asObservable();
  setdimensionsEdited(val) {
    this.dimensionsEdited.next(val);
  }

  private isMsrEdited = new Subject<boolean>();
  isMsrEdited$ = this.isMsrEdited.asObservable();

  private isDsSaved = new Subject<any>();
  $isDsSaved = this.isDsSaved.asObservable();

  getDsSaveStatus(data: any) {
    this.isDsSaved.next(data);
  }

  masterData = [];
  constructor(private assetHarvesterServiceConfig: AssetHarvesterServiceConfig,
    private httpClient: HttpClient, private userService: UserService,
    private config: AppEnvConfig) {
    this.configEnv = assetHarvesterServiceConfig.getEnvironment();
    this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
    this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
    this.configEnv.apiServerUrlAuth = config.getEnv('apiServerUrl');
    this.configEnv.autoTVDServerUrl = config.getEnv('autoTVDServerUrl');
    this.configEnv.msrAggregatorServerUrl = config.getEnv('msrAggregatorServerUrl'),
      this.configEnv.apiWaterServerUrl = config.getEnv('apiWaterServerUrl');
    this.configEnv.siteHarvesterServerUrl = config.getEnv('siteHarvesterServerUrl');
    this.configEnv.siteHarversterAggrServerUrl = config.getEnv('siteHarversterAggrServerUrl');
    this.configEnv.apiTelemetryThresholdsUrl = config.getEnv('apiTelemetryThresholdsUrl');
    this.getAHPermissions();
  }

  public inventoryTypeSource = new BehaviorSubject<string>(null);
  setInventoryType(value: string) {
    this.inventoryTypeSource.next(value);
  }
  getInventoryType() {
    return this.inventoryTypeSource.asObservable();
  }


  public getAssetsCollection(dagtype: string, dagId: number, dataSourceCategory: string, forceLoad?: boolean, graphType?: string): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getAssetsCollection,
      [dagtype, dagId.toString(), dataSourceCategory]) + graphType;
    let key = dagtype + '_' + dagId + '_' + dataSourceCategory;
    if (this.assetCollection[key] && !forceLoad) {
      return of(this.assetCollection[key]);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.assetCollection[key] = response;
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }

  public saveAssetsCollection(dagtype: string, assetCollection, activeTab?): Observable<any> {
    let url = '';
    if (activeTab && activeTab == "asset") {
      url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.saveAssetsCollectionNew, [dagtype]) + this.assetCreatorType;
    }
    else {
      url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.saveAssetsCollection, [dagtype]) + this.assetCreatorType;
    }
    return this.httpClient.put(url, assetCollection).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public saveLimits(assetCollection): Observable<any> {
    let url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.saveLimits);
    return this.httpClient.put(url, assetCollection).map(response => {
      try {
        return response;
      }
      catch (e) {
        return response
      }
    })

  }

  public saveOfflineConfiguration(assetOperationalStatus): Observable<any> {
    let url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.SaveAssetOperationalStatus);
    return this.httpClient.put(url, assetOperationalStatus).map(response => {
      try {
        return response;
      }
      catch (e) {
        return response
      }
    })

  }

  public saveSystemLimits(systemLimits): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.saveContextPointLimits);
    return this.httpClient.put(url, systemLimits).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getAssetSystemGroupCollection(dagtype: string, dagId: number, contextPointType: string, forceLoad?: boolean): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getAssetSystemGroupCollection,
      [dagtype, dagId.toString(), contextPointType]);
    let key = dagtype + '_' + dagId + '_' + contextPointType;
    if (this.siteDagChildren[key] && !forceLoad) {
      return of(this.siteDagChildren[key]);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.siteDagChildren[key] = response;
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }

  public getAccountsByDag(dagId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getAccountsByDag,
      [dagId.toString()]);
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  // on borading details in Account component
  public getTotalAssetsPlannedByDag(dagId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getTotalAssetsPlannedByDag,
      [dagId.toString()]);
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  // save onboarding details
  public postOnBoardingByDag(dagId: number, onBoardingFlag, trailFlag): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.postOnBoardingByDag,
      [dagId.toString(), onBoardingFlag, trailFlag]);
    return this.httpClient.post(url, {}).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  // new api---
  public postOnBoardingBySite(siteId: number, onBoardingFlag, trailFlag): Observable<any> {
    const url = this.parse(this.configEnv.siteHarvesterServerUrl + this.configEnv.apiUrl.postOnBoardingBySiteId,
      [siteId.toString(), onBoardingFlag, trailFlag]);
    return this.httpClient.post(url, {}).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

// new api

  public conversionByUnitType(obj): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.unitConversion);
    return this.httpClient.post(url, obj).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  // Create Guid
  public uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  /**
   * getUserPermission
   */
  private getAHPermissions(): any {
    const user: UserSettings = this.userService.getCurrentSettings();
    user.Permissions.forEach(p => {
      if (p.PermissionId.toString() == USERROLES.ASSETHARVESTERREAD) {
        this.readOnly = true;
      }
      if (p.PermissionId.toString() == USERROLES.ASSETHARVESTERREADWRITE) {
        this.writeAlso = true;
      }
      // if (p.PermissionId.toString() == USERROLES.WFISENSORMAPPING) {
      //   this.wfiSensorAdmin = true;
      // }
      if (p.PermissionId.toString() == USERROLES.WATERHARVESTERCALCULATIONS) {
        this.waterHarvesterCalculations = true;
      }

      if (p.PermissionId.toString() == USERROLES.ASSETHARVESTERDECOMMISSIONWRITE) {
        this.assetHarvesterDecommissionWrite = true;
      }

      if (p.PermissionId.toString() == USERROLES.ASSETHARVESTERADMIN) {
        this.assetHarvesterAdmin = true;
      }

      if (p.PermissionId.toString() == USERROLES.TANKADMIN) {
        this.tankAdmin = true;
      }
    });
  }

  public customSorting(data, field) {
    let test;
    if (data) {
      test = data.sort((a, b) => {
        return a[field] - b[field];
      })
    }
    return test;
  }

  private parse(url: string, params: string[] = []): string {
    let result: string = url;
    if (params.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      result = url.replace(/\{(\d+)\}/g, function (match: string, current: string): string {
        return params[parseInt(current, 10)] !== undefined ? params[parseInt(current, 10)] : '';
      });
    }
    return result;
  }

  public getObjInCollOnValue(coll: any, value: string): any {
    const obj = coll.filter((x) => {
      x.checked = false;
      if (String(x.value).toLocaleLowerCase() === value.toLowerCase()) {
        x.checked = true;
        return x;
      }
    });
    return obj;
  }

  public getContextPointTypeActiveMapCount(dagId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getContextPointTypeActiveMapCount, [dagId.toString()]);
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  public getActiveMapCounts(siteId: number): Observable<any> {
    const url =  this.parse(
      `${this.configEnv.siteHarversterAggrServerUrl }${this.configEnv.apiUrl.
        getActiveMapCounts}?siteCpId=${siteId}`
    );
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  public getScalingInformationForAnalogInput(dataSourceType: string, measurementInstanceIds: Array<number>, contextPointSubTypeId?: number): Observable<any> {
    const apiUrl = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getScalingInformationForAnalogInput;
    let url = this.parse(apiUrl) + '?dataSourceType=' + dataSourceType;
    if (contextPointSubTypeId) {
      url += '&contextPointSubTypeId=' + contextPointSubTypeId;
    }
    return this.httpClient.post(url, measurementInstanceIds).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getInventoryConfiguration(dagId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getInventoryConfiguration,
      [dagId.toString()]);
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  public getTankSerialNumber(dagId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getTankSerialNumber) + '?DagId=' + dagId;
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }
  /** SoldTo ShipTo for Inventory Category */
  public getSoldToShipTo(dagId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSoldToShipTo) + '?DagId=' + dagId;
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }
  /**
   * Get Product details, by default getting total 50 records
   * To change total records, can send one more parameter 'totalRecords' in API request URL
   * @param searchString
   * @param pageIndex
   * @returns
   */
  public getInventoryProducts(searchString, pageIndex): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getproducts) + this.dagId + '/' + searchString + '/' + pageIndex;
    return this.httpClient.get(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  /**
   * Inventory DigiNGG datasource get Latest Calibration details
   * @param assetCpId
   * @returns
   */
  public getCalibrationDatails(assetCpId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getCalibration, [assetCpId.toString()]);
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }
  public updateCalibrate(assetCpId: number, calibration): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.updateCalibrate, [assetCpId.toString()]);
    return this.httpClient.post(url, calibration).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }
  getTimeDataForAssetBasedOnDate(assetCpId: number, endDate: string, numberOfDays: number) {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.TimeDataForAssetBasedOnDate, [assetCpId.toString()]) + '?endDate=' + endDate + '&numberOfDays=' + numberOfDays;
    return this.httpClient.get(url);
  }

  public getAssetsByCategory(dagId: number, categoryId: string, isAssetAdded: boolean): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getAssetsByCategory,
      [dagId.toString(), categoryId]);
    let key = dagId + '_' + categoryId;
    if (this.assetsByCategory[key] && !isAssetAdded) {
      return of(this.assetsByCategory[key]);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.assetsByCategory[key] = response;
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }

  public getAtbAssetProperties(assetCpId: number, isDataSaved: boolean): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getAssetProperties,
      [assetCpId.toString()]);
    let key = assetCpId;
    if (this.atbAssetProperties[key] && !isDataSaved) {
      return of(this.atbAssetProperties[key]);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.atbAssetProperties[key] = response;
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }

  public getOfflineConfig(assetCpId: number, isConfigSaved: boolean): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getOfflineConfig,
      [assetCpId.toString()]);
    let key = assetCpId;
    if (this.offlineConfig[key] && !isConfigSaved) {
      return of(this.offlineConfig[key]);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.offlineConfig[key] = response;
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }

  /**
   * Gets Assets collection Data, for which new APIs are not created
   * To be Removed once coressponding APIs are integrated.
   * @param dagtype
   * @param dagId
   * @param dataSourceCategory
   * @param forceLoad
   * @param graphType
   * @returns
   */
  public getFullRefactorAssetsCollection(dagtype: string, dagId: number, dataSourceCategory: string, forceLoad?: boolean, graphType?: string): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getFullRefactorAssetCollection,
      [dagtype, dagId.toString(), dataSourceCategory]) + graphType;
    let key = dagtype + '_' + dagId + '_' + dataSourceCategory;
    if (this.assetCollection[key] && !forceLoad) {
      return of(this.assetCollection[key]);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.assetCollection[key] = response;
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }

  public getContextPointLimits(assetCpId: number, contextPointTypeId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getContextPointLimits,
      [assetCpId.toString()]);
    let key = assetCpId;
    // if (this.contextPointLimits[key] && !isConfigSaved) {
    //   return of(this.contextPointLimits[key]);
    // } else {
    //Disabling caching of Limits inorder to receive update Limits from controller on tab change.
    return this.httpClient.get(url).map((response: any) => {
      try {
        this.contextPointLimits[key] = response;
        return response;
      } catch (e) {
        return e;
      }
    });
    // }
  }

  getMasterSLaveList(assetList, dagId, categoryId, isAssetSaved): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getMasterSlaveList}`;
    let key = dagId + '_' + categoryId;
    if (this.masterSlaveList[key] && !isAssetSaved) {
      return of(this.masterSlaveList[key]);
    } else {
      return this.httpClient.post(url, assetList).map(
        (response: any) => {
          try {
            this.masterSlaveList[key] = response;
            return response;
          } catch (e) {
            return response;
          }
        });
    }
  }

  public getUnmappedMsrInstances(dagId: number, isMsrSaved: boolean, cId: number): Observable<any> {
    let categoryId = cId === CategoryType.Inventory ? cId : 0;
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getUnMappedMsrInstances,
      [dagId.toString()]) + '?CategoryId=' + categoryId;
    let key = dagId + '_' + categoryId;
    if (this.unMappedMsr[key] && !isMsrSaved) {
      return of(this.unMappedMsr[key]);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.unMappedMsr[key] = response;
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }
  public getFreshUnmappedMsrInstances(dagId: number, isMsrSaved: boolean, cId: number): Observable<any> {
    let categoryId = cId === CategoryType.Inventory ? cId : 0;
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getUnMappedMsrInstances,
      [dagId.toString()]) + '?CategoryId=' + categoryId;
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }
  public getAllAssetsBySiteCpId(siteCpId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getAllAssetsBySiteCpId + '?siteCpId=' + siteCpId);
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  public getMappedMsrInstances(assetCpId: number, isMsrSaved: boolean): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getMappedMsrInstances,
      [assetCpId.toString()]);
    let key = assetCpId;
    if (this.mappedMsr[key] && !isMsrSaved) {
      return of(this.mappedMsr[key]);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.mappedMsr[key] = response;
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }

  public getSystemProperties(systemCpId: number, isDataSaved: boolean): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSystemProperties,
      [systemCpId.toString()]);
    let key = systemCpId;
    if (this.systemProperties[key] && !isDataSaved) {
      return of(this.systemProperties[key]);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.systemProperties[key] = response;
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }

  public getAssetConfig(assetCpId: number, isDataSaved: boolean): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getAssetConfig,
      [assetCpId.toString()]);
    let key = assetCpId;
    if (this.assetConfiguration[key] && !isDataSaved) {
      return of(this.assetConfiguration[key]);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.assetConfiguration[key] = JSON.parse(JSON.stringify(response));
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }

  public getSystems(dagId: number, isSystemAdded: boolean): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSystems,
      [dagId.toString()]);
    let key = dagId;
    if (this.systems[key] && !isSystemAdded) {
      return of(this.systems[key]);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.systems[key] = response;
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }

  /**
   * Delete Asset from AH
   * @param dagId
   * @param deleteItemIdList
   * @param deleteEngineItemTypeId
   * @param measurementMappingDeleteTypeId
   * @returns
   */
  deleteItem(dagId: any, deleteItemIdList: Array<any>, deleteEngineItemTypeId: DeleteEngineItemType, measurementMappingDeleteTypeId: MeasurementMappingDeleteType) {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.deleteItem,
      [dagId.toString(), deleteEngineItemTypeId.toString(), measurementMappingDeleteTypeId.toString()]);
    return this.httpClient.put(url, deleteItemIdList).map(response => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  /**
   * Delete Sensor Measurement from AH
   * @param dagId
   * @param requestBody
   * @param deleteEngineItemTypeId
   * @param measurementMappingDeleteTypeId
   * @returns
   */
  deleteSensorItem(requestBody: any, deleteType: string) {
    const user: UserSettings = this.userService.getCurrentSettings();
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.deleteSensorMapping,
      [deleteType.toString(), user.UserId.toString()]);
    return this.httpClient.put(url, requestBody).map(response => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  isAssetMappedInfo(assetCpId) {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.assetMappedInfo,
      [assetCpId.toString()]);
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }


  getRegionDetails(dagId) {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getRegionDetails, [dagId.toString()]);
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  getInventoryAssetData(dagId) {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getInventoryAssetData, [dagId.toString()]);
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  /**
   * Fetches warning msg to be shown on click of delete asset
   * @param assetCpId
   * @param assetSubTypeId
   * @returns
   */
  deleteAssetWarningMsgs(assetCpId, assetSubTypeId) {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.assetAssociatedServices,
      [assetCpId.toString(), assetSubTypeId.toString()]);
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  public saveMSRDetails(assetCpId: number, assetCreatorType: string, msrDetails) {
    let url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.saveMSRDetails}?assetCpId=${assetCpId}&assetCreatorType=${assetCreatorType}`;
    return this.httpClient.put(url, msrDetails).map(response => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    })
  }
  /**
   * Save a Site as Favorite or not.
   * @param dagId
   * @param isFavorite
   * @returns
   */
  favoriteSite(dagId, isFavorite) {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.favoriteSite,
      [dagId.toString(), isFavorite.toString()]);
    return this.httpClient.put(url, {}).map(response => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  /**
  * Save a Site as Favorite or not --> siteHarvester Service.
  * @param siteId
  * @param isFavorite
  * @returns
  */

  UpdateFavorite(siteId: number, isFavorite: boolean): Observable<boolean> {
    let url = this.parse(this.configEnv.siteHarvesterServerUrl + this.configEnv.apiUrl.siteFavorites,
      [siteId.toString()]);
    url = url + `${isFavorite}`;
    return this.httpClient.put(url, {}).pipe(
      map(response => {
        try {
          return response;
        }
        catch (e) {
          return e;
        }
      })
    );
  }

  /**
   * Get Validations/Warning msgs to be shown while Deleting Asset/Unmapping Measurements
   * @param deleteItemId
   * @param deleteEngineItemTypeId
   * @returns
   */
  deleteValidation(deleteItemIdList: Array<any>, deleteEngineItemTypeId: DeleteEngineItemType) {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.deleteValidation,
      [deleteEngineItemTypeId.toString()]);
    return this.httpClient.put(url, deleteItemIdList).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  /**
   * Create collection Object for either Assets / Systems
   * @param sourceData
   * @param targetData
   * @param collectionObjType
   * @param mode
   * @param forceLoad
   */
  createCollectionObj(sourceData: model.Asset[], newAssetCollection: model.AssetCollection, collectionObjType: ContextPointType, mode: string, forceLoad: boolean) {
    let targetData: model.Asset[]
    if (collectionObjType === ContextPointType.Asset) {
      targetData = newAssetCollection.Assets;
    } else {
      targetData = newAssetCollection.Systems;
    }
    // newAssetCollection.DataSources = [];
    if (sourceData && sourceData.length > 0) {
      sourceData.filter((src) => {
        let currectIndex;
        currectIndex = targetData.findIndex(trgt => trgt.ContextPointId === src.AssetCpId
          || trgt.ContextPointId === src.DagDescendantCpId);

        //insert asset/system into newAssetCollection.Assets/newAssetCollection.Systems obj if Assets/System is empty
        if (!targetData || targetData.length === 0) {
          targetData.push(this.pushInCollection(src, collectionObjType, targetData));
        }
        //insert a asset/system if it doesnt exit in newAssetCollection.Assets/newAssetCollection.System
        else if (targetData && targetData.length > 0 && (currectIndex === -1)) {
          targetData.push(this.pushInCollection(src, collectionObjType, targetData));
        }

        //Updated Asset details in the Category List of Assets
        if (mode === Mode.EDIT && forceLoad) {
          targetData[currectIndex].ContextPointName = src.AssetName;
          targetData[currectIndex].SystemContextPoint.ContextPointId = src.SystemId;
          targetData[currectIndex].SystemContextPoint.ContextPointName = src.SystemName;
          targetData[currectIndex].AreaContextPoint.ContextPointId = src.AreaTypeId;
          targetData[currectIndex].AreaContextPoint.ContextPointName = src.AreaType;
          targetData[currectIndex].AreaContextPoint.ContextPointSubTypeName = src.AreaType;
          targetData[currectIndex].IsMapped = src.IsAssetMapped;
          targetData[currectIndex].IsWaterWatchCreatedAsset = src.IsWaterWatchCreatedAsset;
          let areaObj = this.dagSiteAreas.filter(cat => cat.ContextPointId == src.AreaTypeId);
          if (areaObj && areaObj.length > 0) {
            targetData[currectIndex].AreaContextPoint.ContextPointSubTypeId = areaObj[0].ContextPointSubTypeId;
            targetData[currectIndex].AreaContextPoint.ContextPointIdentifier = `PlatformCoreGuid-${areaObj[0].ContextPointId}`;
            targetData[currectIndex].AreaContextPoint.ContextPointIdentifierParent = `PlatformCoreGuid-${areaObj[0].ParentId}`;
            targetData[currectIndex].AreaContextPoint.ContextPointIdParent = areaObj[0].ParentId;
          }
        }

        //add ports and measurement point to an already inserted Asset.
        else if (targetData && targetData.length > 0 && (currectIndex !== -1) && collectionObjType === ContextPointType.Asset) {
          if (src.PortCpId && targetData[currectIndex] && targetData[currectIndex].Ports
            && targetData[currectIndex].Ports.findIndex(port => port.ContextPointId == src.PortCpId) === -1) {
            targetData[currectIndex].Ports.push(this.pushInPorts(src));
          }
          if (src.MeasurementPointCpId && targetData[currectIndex] && targetData[currectIndex].MeasurementPoints
            && targetData[currectIndex].MeasurementPoints.findIndex(msrPoint => msrPoint.ContextPointId == src.MeasurementPointCpId) === -1) {
            targetData[currectIndex].MeasurementPoints.push(this.pushInMeasurementPoints(src));
          }
        }

        //Adding DataSources
        if (src.DatasourceCpId !== null) {

          //insert DataSources into newAssetCollection.DataSources if DataSources is empty
          if (!newAssetCollection.DataSources || newAssetCollection.DataSources.length === 0) {
            newAssetCollection.DataSources.push(this.pushInDatasource(src));
          }
          //insert DataSources if it doesnt exit in newAssetCollection.DataSources
          else if (newAssetCollection.DataSources && newAssetCollection.DataSources.length > 0 &&
            (newAssetCollection.DataSources.findIndex(ds => ds.ContextPointId == src.DatasourceCpId) === -1)) {
            newAssetCollection.DataSources.push(this.pushInDatasource(src));
          }
        }
      });
    }
    return newAssetCollection;
  }

  /**
   * Returns Asset/System Object to be created in AssetCollection
   * @param details
   * @param contextPointType
   * @param targetData
   */
  pushInCollection(details, contextPointType: ContextPointType, targetData: model.Asset[]) {
    let dataObj = new model.Asset();
    dataObj.DagId = this.dagId;
    dataObj.ContextPointId = contextPointType == ContextPointType.Asset ? details.AssetCpId : details.DagDescendantCpId;
    dataObj.ContextPointName = contextPointType == ContextPointType.Asset ? details.AssetName : details.DagDescendantName;
    dataObj.ContextPointType = contextPointType == ContextPointType.Asset ? 9000 : 8000;
    dataObj.ContextPointTypePrettyName = contextPointType == ContextPointType.Asset ? 'Asset' : 'System';
    dataObj.ContextPointIdParent = contextPointType == ContextPointType.Asset ? details.SystemId : details.GroupCpId;
    dataObj.ContextPointIdentifierParent = contextPointType == ContextPointType.Asset ? `PlatformCoreGuid-${details.SystemId}` : null;
    dataObj.ContextPointStatusType = contextPointType == ContextPointType.Asset ? details.AssetStatusTypeId : -1;
    dataObj.ContextPointSubTypeId = contextPointType == ContextPointType.Asset ? details.AssetSubTypeId : details.DagDescendantSubTypeId;
    dataObj.ContextPointSubTypeName = contextPointType == ContextPointType.Asset ? details.AssetSubTypeName : details.DagDescendantSubTypeName;
    dataObj.IsMapped = contextPointType == ContextPointType.Asset ? details.IsAssetMapped : details.IsMapped;
    dataObj.CategoryId = contextPointType == ContextPointType.Asset ? details.CategoryId : null;
    dataObj.CategoryName = contextPointType == ContextPointType.Asset ? details.CategoryName : null;
    dataObj.SubCategoryId = contextPointType == ContextPointType.Asset ? details.SubCategoryId : null;
    dataObj.SubCategoryName = contextPointType == ContextPointType.Asset ? details.SubCategoryName : null;
    dataObj.IsWaterWatchCreatedAsset = contextPointType == ContextPointType.Asset ? details.IsWaterWatchCreatedAsset : false;
    
    //AreaContextPoint
    dataObj.AreaContextPoint.ContextPointId = contextPointType == ContextPointType.Asset ? details.AreaTypeId : details.GroupCpId;
    dataObj.AreaContextPoint.ContextPointName = contextPointType == ContextPointType.Asset ? details.AreaTypeName : details.GroupName;
    dataObj.AreaContextPoint.ContextPointType = 7000;
    dataObj.AreaContextPoint.ContextPointTypePrettyName = 'Group';
    dataObj.AreaContextPoint.ContextPointStatusType = -1;
    dataObj.AreaContextPoint.ContextPointStatusTypePrettyName = 'Active';
    dataObj.AreaContextPoint.OrderInTree = 2;
    dataObj.AreaContextPoint.ContextPointSubTypeName = dataObj.AreaContextPoint.ContextPointName;
    if (contextPointType == ContextPointType.Asset) {
      let areaObj = this.dagSiteAreas.filter(cat => cat.ContextPointId == details.AreaTypeId);
      if (areaObj && areaObj.length > 0) {
        dataObj.AreaContextPoint.ContextPointSubTypeId = areaObj[0].ContextPointSubTypeId;
        dataObj.AreaContextPoint.ContextPointIdentifier = `PlatformCoreGuid-${areaObj[0].ContextPointId}`;
        dataObj.AreaContextPoint.ContextPointIdentifierParent = `PlatformCoreGuid-${areaObj[0].ParentId}`;
        dataObj.AreaContextPoint.ContextPointIdParent = areaObj[0].ParentId;
      }
      dataObj.AreaContextPoint.DatasourceMeasurementMaps = [];
    }

    //SystemContextPoint
    dataObj.SystemContextPoint.ContextPointId = contextPointType == ContextPointType.Asset ? details.SystemId : details.DagDescendantCpId;
    dataObj.SystemContextPoint.ContextPointName = contextPointType == ContextPointType.Asset ? details.SystemName : details.DagDescendantName;
    dataObj.SystemContextPoint.ContextPointType = 8000;
    dataObj.SystemContextPoint.ContextPointTypePrettyName = 'System';
    dataObj.SystemContextPoint.ContextPointStatusType = -1;
    dataObj.SystemContextPoint.ContextPointStatusTypePrettyName = 'Active';
    dataObj.SystemContextPoint.OrderInTree = 3;
    if (contextPointType == ContextPointType.Asset) {
      let systemObj = this.dagSiteSystems.filter(cat => cat.ContextPointId == details.SystemId);
      if (systemObj && systemObj.length > 0) {
        dataObj.SystemContextPoint.ContextPointIdentifier = systemObj ? `PlatformCoreGuid-${systemObj[0].ContextPointId}` : null;
        dataObj.SystemContextPoint.ContextPointIdentifierParent = `PlatformCoreGuid-${systemObj[0].ParentId}`;
        dataObj.SystemContextPoint.ContextPointIdParent = systemObj[0].ParentId;
        dataObj.SystemContextPoint.ContextPointSubTypeId = systemObj[0].ContextPointSubTypeId;
        dataObj.SystemContextPoint.ContextPointSubTypeName = systemObj[0].ContextPointSubTypeName;
      }
      dataObj.AssetProperties.AtbAssetProperty = null;

      //Asset Limits details
      dataObj.AssetProperties.AssetLimits.AssetContextPointId = details.AssetCpId;
      dataObj.AssetProperties.AssetLimits.AssetContextPointTypeId = 9000;
      dataObj.AssetProperties.AssetLimits.AssetContextPointSubTypeId = details.AssetSubTypeId;

      //Adding Ports and Measurement Points
      if (details.MeasurementPointCpId) {
        dataObj.MeasurementPoints.push(this.pushInMeasurementPoints(details));
      }
      if (details.PortCpId) {
        dataObj.Ports.push(this.pushInPorts(details));
      }
    }
    // targetData.push(dataObj);
    return dataObj;
  }

  /**
 * Returns Ports object to be created inside Assets
 * @param portDtls
 * @returns
 */
  pushInPorts(portDtls) {
    let portsObj = new model.TreeContextPoint();
    portsObj.ContextPointId = portDtls.PortCpId;
    portsObj.ContextPointIdentifier = `PlatformCoreGuid-${portDtls.PortCpId}`;
    portsObj.ContextPointIdParent = portDtls.AssetCpId;
    portsObj.ContextPointIdentifierParent = `PlatformCoreGuid-${portDtls.AssetCpId}`;
    portsObj.ContextPointName = portDtls.PortName;
    portsObj.ContextPointSubTypeId = portDtls.PortSubTypeId;
    portsObj.ContextPointSubTypeName = portDtls.PortSubTypeName;
    portsObj.ContextPointType = 11000;
    portsObj.ContextPointTypePrettyName = 'Ports';
    portsObj.PortSubTypeId = portDtls.PortSubTypeId;
    // if (portDtls.Measurements) {
    //   let measurements = portDtls.Measurements.split(',')
    //   portsObj.MeasurementInstances = measurements;
    // }
    return portsObj;
  }

  /**
* Returns Measurement Point object to be created inside Assets
* @param msrpointDtls
* @returns
*/
  pushInMeasurementPoints(msrpointDtls: model.Asset) {
    let msrpointObj = new model.TreeContextPoint();
    msrpointObj.ContextPointId = msrpointDtls.MeasurementPointCpId;
    msrpointObj.ContextPointIdentifier = `PlatformCoreGuid-${msrpointDtls.MeasurementPointCpId}`;
    msrpointObj.ContextPointIdParent = msrpointDtls.AssetCpId;
    msrpointObj.ContextPointIdentifierParent = `PlatformCoreGuid-${msrpointDtls.AssetCpId}`;
    msrpointObj.ContextPointName = msrpointDtls.MeasurementPointName;
    msrpointObj.ContextPointSubTypeId = msrpointDtls.MeasurementPointSubTypeId;
    msrpointObj.ContextPointSubTypeName = msrpointDtls.MeasurementPointSubTypeName;
    msrpointObj.ContextPointType = msrpointDtls.MeasurementPointTypeId;
    msrpointObj.ContextPointTypePrettyName = 'Measurement Point';
    return msrpointObj;
  }

  /**
   * Returns Datasources object to be created in AssetCollection
   * @param assetDtls
   * @returns
   */
  pushInDatasource(assetDtls) {
    let dataSrcObj = new model.DataSource;
    dataSrcObj.ContextPointId = assetDtls.DatasourceCpId ? assetDtls.DatasourceCpId : assetDtls.FromMeasurementInstance ? assetDtls.FromMeasurementInstance.ContextPointId : null;
    dataSrcObj.ContextPointSubTypeId = assetDtls.DatasourceSubTypeId ? assetDtls.DatasourceSubTypeId : assetDtls.FromMeasurementInstance ? assetDtls.FromMeasurementInstance.ContextPointSubTypeId : null;
    dataSrcObj.ContextPointSubTypeName = assetDtls.DatasourceSubTypeName ? assetDtls.DatasourceSubTypeName : assetDtls.FromMeasurementInstance ? assetDtls.FromMeasurementInstance.ContextPointSubTypeName : null;
    dataSrcObj.ContextPointName = assetDtls.DatasourceName ? assetDtls.DatasourceName : assetDtls.FromMeasurementInstance ? assetDtls.FromMeasurementInstance.ContextPointName : '';
    dataSrcObj.ContextPointType = assetDtls.DatasourceTypeId ? assetDtls.DatasourceTypeId : assetDtls.FromMeasurementInstance ? assetDtls.FromMeasurementInstance.ContextPointTypeId : null;
    dataSrcObj.DataSourceTypeAlias = this.setDatasourceTypeName(dataSrcObj.ContextPointType, dataSrcObj.ContextPointSubTypeId);
    dataSrcObj.DataSourceFriendlyName = assetDtls.DatasourceName ? assetDtls.DatasourceName : dataSrcObj.ContextPointName;
    dataSrcObj.IsLast6MonthActive = assetDtls.IsLast6MonthActive && assetDtls.IsLast6MonthActive === 1 ? 1 : 0;


    //Indentify if a Datasource is mapped to an Inventory Asset
    if (assetDtls.CategoryId == CategoryType.Inventory && (assetDtls.DatasourceCpId !== '' || assetDtls.DatasourceCpId !== null || assetDtls.DatasourceCpId !== 0)
      && (assetDtls.DatasourceTypeId === 13000 && assetDtls.DatasourceSubTypeId === 2)) {
      dataSrcObj.IsInventoryDS = true;
    }
    return dataSrcObj;
  }

  setDatasourceTypeName(DatasourceTypeId, DatasourceSubTypeId): string {
    if (DatasourceTypeId === 14000) {
      if (DatasourceSubTypeId === 1 || DatasourceSubTypeId === 2 || DatasourceSubTypeId === 3 || DatasourceSubTypeId === 4 || DatasourceSubTypeId === 10) {
        return 'Controller';
      }
      else if (DatasourceSubTypeId === 5 || DatasourceSubTypeId === 6 || DatasourceSubTypeId === 8) return 'Gateway';
      else if (DatasourceSubTypeId === 7) return 'SIM';
      else if (DatasourceSubTypeId === 11) return 'Ecolab Data Collector';
    } else if (DatasourceTypeId === 13000 && (DatasourceSubTypeId === 1 || DatasourceSubTypeId === 10 || DatasourceSubTypeId === 11)) {
      return 'LIMS';
    } else if (DatasourceTypeId === 13000 && DatasourceSubTypeId === 2) {
      return 'E-Data';
    } else if ((DatasourceTypeId === 13000 && DatasourceSubTypeId === 3)//mannual ds
      || (DatasourceTypeId === 12000 &&
        (DatasourceSubTypeId === 6 || DatasourceSubTypeId === 24 ||
          DatasourceSubTypeId === 31 || DatasourceSubTypeId === 37 ||
          DatasourceSubTypeId === 38)) //automatic ds
      || (DatasourceTypeId === 8000 && DatasourceSubTypeId === 6) //system
    ) {
      return 'Customer Data';
    } else if (DatasourceTypeId === 12000 && (DatasourceSubTypeId === 7 || DatasourceSubTypeId === 9)) {
      return 'Sensor';
    } else if (this.categoryId === CategoryType.Inventory && DatasourceTypeId === ContextPointType.AutomaticDatasource && DatasourceSubTypeId === AutoDatasourceSubType.DIGINGG) {
      return DataSourceType.GATEWAY;
    } else if (DatasourceTypeId === 12000 && DatasourceSubTypeId !== 35) {
      return 'Controller';
    } else if (DatasourceTypeId === 12000 && DatasourceSubTypeId === 35) {
      return 'Zigbee';
    } else if (DatasourceTypeId === 13000 && DatasourceSubTypeId === 65) {
      return 'Partner Data';
    } else if (DatasourceTypeId === 13000 && DatasourceSubTypeId === 66) {
      return 'Downstream E-Data';
    } else {
      return '';
    }
  }

  /**
   * Get Water Quality enabled Asset Types or Ports
   * @param assetSubtypeId
   * @param categoryId
   * @returns
   */
  public getWQAssetTypePort(searchType: string, searchTypeId: number): Observable<any> {
    let url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getWQAssetTypePort);
    if (searchType == 'AssetType')
      url = url + '?categoryId=' + searchTypeId;
    else
      url = url + '?assetSubTypeId=' + searchTypeId;
    let key = `${searchType}_${searchTypeId}`;
    if (this.assetType[key]) {
      return of(this.assetType[key]);
    } else {
      return this.httpClient.get(url).map(response => {
        try {
          this.assetType[key] = response;
          return response;
        } catch (e) {
          return response;
        }
      });
    }
  }

  public GetUnitsByDimensionalKey(params: any): Observable<any> {

    let url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getUnitsByDimensionalKey}`;

    return this.httpClient.post(url, params).map(response => {
      return response;
    });
  }

  public GetROTrainNormalizeParameter(assetCpId, siteCpId): Observable<any> {

    let url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getROTrainNormalizeParameter,
      [assetCpId.toString(), siteCpId.toString()]);

    return this.httpClient.get(url).map(response => {
      return response;
    });
  }

  public GetROTrainReferenceValues(assetCpId, referenceDate): Observable<any> {

    let url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.GetROTrainNormalizeReferenceValues,
      [assetCpId.toString(), referenceDate.toString()]);

    return this.httpClient.get(url).map(response => {
      return response;
    });
  }

  public SaveNormalizeDetails(params: any): Observable<any> {

    let url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.saveNormalizeDetails}`;

    return this.httpClient.post(url, params).map(response => {
      return response;
    });
  }

  public getPortsCountInAssetByDagCpIdCategoryId(dagId: number, categoryId: number, refreshData: boolean): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getChildrenCountInAssetByDagCpIdCategoryId, [dagId.toString(), categoryId.toString()]);
    let key = dagId + '_' + categoryId;
    if (this.assetByDagCpIdCategoryId[key] && !refreshData) {
      return of(this.assetByDagCpIdCategoryId[key]);
    }
    else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.assetByDagCpIdCategoryId[key] = response;
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }

  public getSiteLevelMeasurements(dagId: number, siteCpId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSiteLevelMeasurements) + '?siteCpId=' + siteCpId;
    let key = dagId + '_' + siteCpId;
    if (this.siteLevelMeasurements[key]) {
      return of(this.siteLevelMeasurements[key]);
    }
    else {
      return this.httpClient.get(url).map(response => {
        try {
          this.siteLevelMeasurements[key] = response;
          return response;
        } catch (e) {
          return response;
        }
      });
    }
  }

  public getCalculatedMeasurements(contextPointId: number, isMsrSaved: boolean): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getCalculatedMeasurements) + '?contextPointId=' + contextPointId;
    if (this.assetLevelCalculations[contextPointId] && !isMsrSaved) {
      return of(this.assetLevelCalculations[contextPointId]);
    } else {
      return this.httpClient.get(url).map(response => {
        try {
          this.assetLevelCalculations[contextPointId] = response;
          return response;
        } catch (e) {
          return response;
        }
      });
    }
  }

  public getCalculatedMeasurementsVariableDetails(measurementInstanceId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getCalculatedMeasurementsVariableDetails) + '?measurementInstanceId=' + measurementInstanceId;
    if (this.assetLevelCalculationsVariableDetails[measurementInstanceId]) {
      return of(this.assetLevelCalculationsVariableDetails[measurementInstanceId]);
    } else {
      return this.httpClient.get(url).map(response => {
        try {
          this.assetLevelCalculationsVariableDetails[measurementInstanceId] = response;
          return response;
        } catch (e) {
          return response;
        }
      });
    }
  }

  public getAssetLevelMeasurements(dagId: number, assetId: number, edataOnly: boolean, categoryId: number): Observable<any> {
    let category = (categoryId == 45) ? categoryId : 0;
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getAssetLevelMeasurements) + '?assetCpId=' + assetId + '&IsEdataOnly=' + edataOnly + '&categoryId=' + category;
    // let key = dagId + '_' + assetId;
    // if (this.assetLevelMeasurements[key]) {
    //   return of(this.assetLevelMeasurements[key]);
    // }
    // else {
    return this.httpClient.get(url).map(response => {
      try {
        // this.assetLevelMeasurements[key] = response;
        return response;
      } catch (e) {
        return response;
      }
    });
    //}
  }

  public getMeasurementContextPointMapByContextPointId(assetId: number): Observable<any> {
    const url = this.parse(this.configEnv.msrAggregatorServerUrl + this.configEnv.apiUrl.getMeasurementContextPointMapByContextPointId,
      [assetId.toString()]);
    return this.httpClient.get(url).map((res) => {
      try {
        return res;
      } catch (e) {
        return e;
      }
    })
  }

  public getEDataTourConfigurations(siteId: any): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getTourConfiguration}?siteCpId=${siteId}`;
    return this.httpClient.get(url).map((response: any) => {
      try {
        return this.sortConfig(response);
      } catch (e) {
        return response;
      }
    });
  }

  sortConfig(dataList: any) {
    return dataList.sort((a, b) => (a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1));
  }


  public getEdataContextPointsAndMeasurementsBySiteCpId(siteId: any, fetchlatest = false): Observable<any> {
    const getConfigList = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getEdataContextPointsAndMeasurementsBySiteCpId}?siteCpId=${siteId}`;
    if (this.tourConfiguration[siteId] && !fetchlatest) {
      return of(this.tourConfiguration[siteId]);
    } else {
      return this.httpClient.get(getConfigList).map(response => {
        try {
          this.tourConfiguration[siteId] = response;
          return response;
        } catch (e) {
          return response;
        }
      });
    }
  }

  getEdataGridResponse(configData: any): Array<SamplePointsAndMeasurements> {

    const measurementInstanceData: Array<model.SamplePointsAndMeasurements> = [];
    const sortedMeasurementInstanceData = this.sortArray(configData);

    sortedMeasurementInstanceData.forEach(config => {
      let measurementInstanceConfigurationsCount = 0
      if (config.MeasurementInstanceConfigurations && config.MeasurementInstanceConfigurations.length > 0) {
        config.MeasurementInstanceConfigurations.forEach(measurement => {
          const displayName = measurement.MeasurementInstanceLite.DisplayName ? measurement.MeasurementInstanceLite.DisplayName : '';
          const uniqueIdentifierId = measurement.MeasurementInstanceLite.StuiTypeId ? measurement.MeasurementInstanceLite.StuiTypeId : 0;
          const uniqueIdentifierName = measurement.MeasurementInstanceLite.StuiName ? measurement.MeasurementInstanceLite.StuiName : '';
          const measurementSource = measurement.MeasurementInstanceLite.MeasurementSource ? measurement.MeasurementInstanceLite.MeasurementSource : -1;
          let measurementName = '';
          if (uniqueIdentifierId === 0 && measurementSource === -1) {
            measurementName = displayName;
          } else if (uniqueIdentifierId === 0 && measurementSource === "Default") {
            measurementName = displayName;
          } else if (uniqueIdentifierId !== 0 && measurementSource === -1) {
            measurementName = displayName + ' | ' + uniqueIdentifierName;
          } else if (uniqueIdentifierId !== 0 && measurementSource === "Default") {
            measurementName = displayName + ' | ' + uniqueIdentifierName;
          } else if (uniqueIdentifierId !== 0 && measurementSource !== "Default") {
            measurementName = displayName + ' | ' + uniqueIdentifierName + ' | ' + measurementSource;
          } else if (uniqueIdentifierId === 0 && measurementSource !== "Default") {
            measurementName = displayName + ' | ' + measurementSource;
          }

          const contextPointName = config.ContextPointLite.ParentName ? config.ContextPointLite.ParentName + (config.ContextPointLite.ContextPointName ? ' | ' + config.ContextPointLite.ContextPointName : '')
            : (config.ContextPointLite.ContextPointName ? config.ContextPointLite.ContextPointName : '');

          if (measurement.MeasurementInstanceLite.OriginType !== 3 || this.checkCalculatedMsrIsConfigured(measurement)) {
            measurementInstanceConfigurationsCount++;
            measurementInstanceData.push({
              ContextPointId: measurement.MeasurementInstanceLite.MeasurementInstanceId ? measurement.MeasurementInstanceLite.MeasurementInstanceId : 0,
              MeasurementName: measurementName,
              ParentContextPointId: measurement.MeasurementInstanceLite.ContextPointId ? measurement.MeasurementInstanceLite.ContextPointId : 0,
              OriginType: measurement.MeasurementInstanceLite.OriginType ? measurement.MeasurementInstanceLite.OriginType : 0,
              IsParent: false,
              SamplePointName: measurement.EnvisionSamplePointName ? measurement.EnvisionSamplePointName : contextPointName,
              MeasurementInstanceId: measurement.MeasurementInstanceLite.MeasurementInstanceId ? measurement.MeasurementInstanceLite.MeasurementInstanceId : 0,
              MeasurementInstanceCalculationMaps: measurement['MeasurementInstanceCalculationMaps'] && Array.isArray(measurement.MeasurementInstanceCalculationMaps) ? measurement.MeasurementInstanceCalculationMaps : []
            });
          }
        });
      }

      if (config.ContextPointLite && config.ContextPointLite !== null && config.MeasurementInstanceConfigurations && measurementInstanceConfigurationsCount > 0) {
        const contextPointName = config.ContextPointLite.ParentName ? config.ContextPointLite.ParentName + (config.ContextPointLite.ContextPointName ? ' | ' + config.ContextPointLite.ContextPointName : '')
          : (config.ContextPointLite.ContextPointName ? config.ContextPointLite.ContextPointName : '');
        measurementInstanceData.push({
          ContextPointId: config.ContextPointLite.ContextPointId ? config.ContextPointLite.ContextPointId : 0,
          MeasurementName: contextPointName,
          ContextPointTypePrettyName: config.ContextPointLite.ContextPointTypePrettyName ? config.ContextPointLite.ContextPointTypePrettyName : '',
          IsParent: true,
          childIds: config.MeasurementInstanceConfigurations.filter(e => { return e["MeasurementInstanceLite"]["MeasurementInstanceId"] }).map(a => { return a.MeasurementInstanceLite.MeasurementInstanceId })
        });
      }
    });
    return measurementInstanceData;
  }

  checkCalculatedMsrIsConfigured(msr) {
    if (msr['MeasurementInstanceCalculationMaps'] && Array.isArray(msr.MeasurementInstanceCalculationMaps)
      && msr['CalculationConfiguration'] && msr.CalculationConfiguration['InputGroups']
      && msr.MeasurementInstanceCalculationMaps.length === msr.CalculationConfiguration.InputGroups.length) {
      return true;
    } else {
      false;
    }
  }

  sortArray(dataList: any) {
    return dataList.sort((a, b) => (a.ContextPointLite.ContextPointTypePrettyName < b.ContextPointLite.ContextPointTypePrettyName ? -1 : 1));
  }

  getConfigurationSetup(siteCpId: number, shouldIncludeAllTours: boolean): Observable<any> {
    const getConfigList = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getConfigurationSetup) + '?siteCpId=' + siteCpId + '&shouldIncludeAllTours=' + shouldIncludeAllTours;
    return this.httpClient.get(getConfigList).map((response: any) => {
      try {
        return this.sortConfig(response);
      } catch (e) {
        return response;
      }
    });
  }

  public SaveMultipleConfiguration(configChanges): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.SaveMultipleConfiguration);
    return this.httpClient.post(url, configChanges).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public SaveAndReturnConfiguration(configChanges): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.SaveAndReturnConfiguration);
    return this.httpClient.post(url, configChanges).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getCalcConfig(): Observable<CalculationConfiguration[]> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getCalcConfig}`;
    if (this.calculationConfig) {
      return of(this.calculationConfig);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.calculationConfig = response;
          return response;
        } catch (e) {
          return response;
        }
      });
    }
  }
  public getVariableMeasurementsByTypeId(siteCpId: number, calculationTypeId: number, assetCpId: number): Observable<assetPortMsrDtls[]> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getVariableMeasurementsByTypeId}?siteCpId=${siteCpId}&assetCpId=${assetCpId}&calculationTypeId=${calculationTypeId}`;
    // if (this.calculationConfig) {
    //   return of(this.calculationConfig);
    // } else {
    return this.httpClient.get(url).map((response: any) => {
      try {
        // this.calculationConfig = response;
        return response;
      } catch (e) {
        return response;
      }
    });
    // }
  }

  public getMeasurementInstanceCalculationMapByMeasurementInstanceId(measurementInstanceId: number): Observable<measurementInstanceCalculationMap> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getMeasurementInstanceCalculationMapByMeasurementInstanceId}?measurementInstanceId=${measurementInstanceId}`;
    // if (this.getAllMeasurementCalcsByMeasurementInstanceId[measurementInstanceId]) {
    //   return of(this.getAllMeasurementCalcsByMeasurementInstanceId[measurementInstanceId]);
    // } else {
    return this.httpClient.get(url).map((response: any) => {
      try {
        // this.getAllMeasurementCalcsByMeasurementInstanceId[measurementInstanceId] = response;
        return response;
      } catch (e) {
        return response;
      }
    });
    // }
  }

  public getMeasurementCalcs(assetCpId: number, portCpId: number): Observable<measurementInstanceCalculationMap> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getMeasurementCalcs}?assetCpId=${assetCpId}&portCpId=${portCpId}`;
    if (this.getMeasurementCalcsByportCpId[portCpId]) {
      return of(this.getMeasurementCalcsByportCpId[portCpId]);
    } else {
      return this.httpClient.get(url).map((response: any) => {
        try {
          this.getMeasurementCalcsByportCpId[portCpId] = response;
          return response;
        } catch (e) {
          return response;
        }
      });
    }
  }

  getSiteLevelMsrs(dagId, measurementId, stuiTypeId, stuiId): Observable<assetPortMsrDtls[]> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getSiteLevelMsrs}/${dagId}/${measurementId}/${stuiTypeId}/${stuiId}`;
    let siteLevelMsrs = dagId + '_' + measurementId + '_' + stuiTypeId + '_' + stuiId;
    // if (this.siteLevelMsrs[siteLevelMsrs]) {
    //   return of(this.siteLevelMsrs[siteLevelMsrs]);
    // } else {
    return this.httpClient.get(url).map((response: any) => {
      try {
        // this.siteLevelMsrs[siteLevelMsrs] = response;
        return response;
      } catch (e) {
        return response;
      }
    });
    // }
  }

  public getStui(stuiTypeId?: number): Observable<IStuiType> {
    this.url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getStui}${stuiTypeId}`;
    return this.httpClient.get(this.url)
      .map((response: any) => response);
  }

  public getUsefulMeasurements(selectedAssetTypeId, selectedAssetSubTypeId, forceLoad: boolean): Observable<any> {
    this.url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getUsefulMeasurements}/${selectedAssetTypeId}/${selectedAssetSubTypeId}`;
    let key = `${selectedAssetTypeId}_${selectedAssetSubTypeId}`;
    // if (this.usefulMsrs[key] && !forceLoad) {
    //   return of(this.usefulMsrs[key]);
    // } else {
    return this.httpClient.get(this.url).map(response => {
      try {
        this.usefulMsrs[key] = response;
        return response;
      } catch (e) {
        return e;
      }
    });
    // }
  }

  public getMeasureType(): Observable<IMeasurementTypes[]> {
    if (this.measureTypeList.length == 0) {
      this.url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getMeasureType}/CategorySpecificMeasurements`;
      return this.httpClient.get(this.url)
        .map((response: any) => {
          this.measureTypeList = response;
          return response;
        });
    } else
      return of(this.measureTypeList)
  }

  retryFailedLimits(assetCpId, reqBody): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.retryFailedLimits, [assetCpId]);
    return this.httpClient.post(url, reqBody).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getMeasurementSource(): Observable<any> {
    if (!this.measurementSourceOptions.length) {
      this.url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getMeasurementSource}`;
      return this.httpClient.get(this.url)
        .map((response: any) => {
          this.measurementSourceOptions = response;
          return response;
        });
    } else {
      return of(this.measurementSourceOptions);
    }
  }

  public getStuiType(): Observable<IStuiType> {
    this.url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getStuiType}`;
    return this.httpClient.get(this.url)
      .map((response: any) => response);
  }

  public getUOMByNalcoNumericsUnitOrSpeciesorMeasurementID(nalcounitname: string, measurementId): Observable<any> {
    if (this.uomOptionsListByNalcounitname[`${nalcounitname}_${measurementId}`]) {
      return of(this.uomOptionsListByNalcounitname[`${nalcounitname}_${measurementId}`]);
    } else {
      this.url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getUOMByNalcoNumericsUnitOrSpeciesorMeasurementID}` + '?NalcoNumericsUnitOrSpecies=' + nalcounitname + '&MeasurementID=' + measurementId;
      return this.httpClient.get(this.url)
        .map((response: any) => {
          this.uomOptionsListByNalcounitname[`${nalcounitname}_${measurementId}`] = response;
          return response;
        });
    }
  }

  public getStuiTypeByStuiTypeId(StuiTypeId: any, showUId = false): Observable<any> {
    if (showUId) {
      if (this.stuitypesListByStuiTypeId[StuiTypeId]) {
        return of(this.stuitypesListByStuiTypeId[StuiTypeId]);
      } else {
        const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getStuiTypeByStuiTypeId, [StuiTypeId]);

        return this.httpClient.get(url)
          .map((response: any) => {
            this.stuitypesListByStuiTypeId[StuiTypeId] = response;
            return response;
          });
      }
    } else {
      return of([])
    }
  }

  getDropDownOptions(NalcoNumericsUnitOrSpecies, StuiTypeId, showUId, measurementId): Observable<any> {
    return forkJoin([this.getUOMByNalcoNumericsUnitOrSpeciesorMeasurementID(NalcoNumericsUnitOrSpecies, measurementId), this.getStuiTypeByStuiTypeId(StuiTypeId, showUId)])
      .pipe(
        map(res => {
          return {
            uomOptions: res[0],
            stuiTypes: res[1]
          }
        })
      )
  }

  public checkIfMeasurementExists(measurementChanges): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.checkIfMeasurementExists);
    return this.httpClient.post(url, measurementChanges).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public saveEdataMeasurements(measurementChanges, assetCpId): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.saveEdataMeasurements}?assetCpId=${assetCpId}&assetCreatorType=None`;
    return this.httpClient.put(url, measurementChanges).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public SaveMeasurementsForAllDataSources(measurementChanges, assetCpId, edataOnly: boolean): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.SaveMeasurementsForAllDataSources}?assetCpId=${assetCpId}&IsEdataMap=` + edataOnly + `&assetCreatorType=AssetHarvester`;
    return this.httpClient.put(url, measurementChanges).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public createEdataInventoryMeasurements(measurementChanges, assetCpId, edataOnly: boolean): Observable<any> {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.createEdataInventoryMeasurements}/${assetCpId}`;
    return this.httpClient.put(url, measurementChanges).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  getEDataMeasurements(): Observable<any> {
    if (!this.edataMeasurementList.length) {
      const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.GetEDataMeasurements}?shouldReturnCalculatedMeasurements=false`;
      return this.httpClient.get(url)
        .map((response: any) => {
          try {
            this.edataMeasurementList = response;
            return response;
          } catch (e) {
            return response;
          }
        });
    } else {
      return of(this.edataMeasurementList);
    }
  }

  private msrSavedChanges = new Subject<any>();
  msrSavedChanges$ = this.msrSavedChanges.asObservable();

  getMsrSavedChanges(asset, savedChanges: boolean, isDeleteFlag?: boolean, displayMsg?: boolean, offlineActivation?: boolean, showCalibration?: boolean) {
    let saveData = {
      'assetCpId': asset,
      'savedChanges': savedChanges,
      'isDeleteFlag': isDeleteFlag,
      'displayMsg': displayMsg,
      'offlineActivation': offlineActivation !== undefined ? offlineActivation : null,
      'showcalibration': showCalibration
    }
    this.msrSavedChanges.next(saveData);
  }

  private eDataSavedChanges = new Subject<any>();
  eDataSavedChanges$ = this.eDataSavedChanges.asObservable();

  getEdataSavedChanges(asset, savedChanges: boolean) {
    let saveData = {
      'assetCpId': asset,
      'savedChanges': savedChanges,
    }
    this.eDataSavedChanges.next(saveData);
  }

  fetchCustomerBySiteID(siteId): Observable<any> {
    let fetchCustomerUrl = `${this.configEnv.apiServerUrl}SiteContextPointMaps`;
    return this.httpClient.post(fetchCustomerUrl, siteId).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public fetchMeasurementMappingLocationBySiteCpId(siteCpId: number, measurementIds: number[], contextPointSubTypeIdList: number[]) {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getMeasurementMapLocationsBySiteCpId}/${siteCpId}`;
    const payload = {
      "CategoryId": 5,
      "MeasurementIds": measurementIds,
      "ContextPointSubTypeIdList": contextPointSubTypeIdList,
      "IsGetSiteLevelMappings": true
    }

    return this.httpClient.post(url, payload).map((response: any[]) => {
      if (!this.tvdMeasurements[siteCpId]) {
        this.tvdMeasurements[siteCpId] = [];
      }
      try {
        this.tvdMeasurements[siteCpId] = [...response];
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getMeasurementMappingLocationBySiteCpId(siteCpId: number, measurementIds: number[], contextPointSubTypeIdList: number[], hardRefetch = false): Observable<any> {
    if (!this.tvdMeasurements[siteCpId] || hardRefetch) {
      return this.fetchMeasurementMappingLocationBySiteCpId(siteCpId, measurementIds, contextPointSubTypeIdList);
    } else {
      return of(this.tvdMeasurements[siteCpId]);
    }
  }

  public searchSites(options: any, sitesType): Observable<contracts.ISitesResponse> {
    sitesType = sitesType ? sitesType : 'mySites';
    this.url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDagPagingByUser);

    const obj: any = {

      "Dag": "NalcoWater",
      "PageIndex": options.offset + 1,
      "PageSize": options.limit,
      "SearchString": options.searchTerm,
      "SiteSearchType": sitesType,
      "ServiceTypeId": "None",
      "SearchIds": [
        0
      ]
    };
    return this.httpClient.post(this.url, obj).map((response: any) => {
      try {
        this.siteRes = response;
      } catch (e) {
        this.siteRes = response;
      }
      this.sitesResponse = {
        limit: options.limit,
        offset: options.offset,
        status: 0,
        TotalRecords: this.siteRes.TotalRecords,
        Sites: this.siteRes.Sites
      };
      return this.sitesResponse;
    });
  }

  setDatasourceStatus(value: boolean): void {
    this.isDataSourceUpdated.next(value)
  }

  getDataSourceStatus(): Observable<boolean> {
    return this.isDataSourceUpdated$;
  }

  private linkClickedSubject = new Subject<void>();
  linkClicked$ = this.linkClickedSubject.asObservable();

  emitLinkClicked() {
    this.linkClickedSubject.next();
  }

  getTVDSettingsBySiteCpId(workIdentifier: string, assetCpId: number, siteCpId: number, forceLoad?: boolean): Observable<any> {
    const url = `${this.configEnv.autoTVDServerUrl}${this.configEnv.apiUrl.getInputConfiguration}/${workIdentifier}/${assetCpId}`;
    let key = siteCpId + '_' + assetCpId;
    if (this.assetTvdConfigurations[key] && !forceLoad) {
      return of(this.assetTvdConfigurations[key]);
    } else {
      return this.httpClient.get(url).map((response: any[]) => {
        try {
          this.assetTvdConfigurations[key] = response;
          return response;
        } catch (e) {
          return e;
        }
      });
    }
  }

  getBaselineAndTarget(payload) {
    const url = `${this.configEnv.apiTelemetryThresholdsUrl}${this.configEnv.apiUrl.getBaselineAndTarget}`;
    return this.httpClient.post(url, payload).map(response => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    })
  }
  getMappingLocationMeasurementDetails(siteCpId: number, payload) {
    const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.GetMappingLocationMeasurementDetails}/${siteCpId}`;
    return this.httpClient.post(url, payload).map((response: any[]) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  SaveInputConfiguration(payload, partialSave = false) {
    const url = `${this.configEnv.autoTVDServerUrl}${this.configEnv.apiUrl.saveInputConfiguration}?partialSave=${partialSave}`;
    return this.httpClient.post(url, payload).map((response: any[]) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    });
  }

  public getFlowMetersData(): Observable<any> {

    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getFlowMeters);
    return this.httpClient.get(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getUnitUsageById(unitType: number): Observable<any> {
    this.url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getUnitUsageById + unitType.toString());
    return this.httpClient.get(this.url).map((response: any) => {
      if (unitType === UnitUsageType.Flow) {
        this.flowUnits = response;
      } else if (unitType === UnitUsageType.Volume) {
        this.volumeUnits = response;
      } else if (unitType === UnitUsageType.Temperature) {
        this.temperatureUnits = response;
      }
    });
  }

  public getSensorMappingData(assetContextPointId): Observable<any> {

    const url = this.parse(this.configEnv.msrAggregatorServerUrl + this.configEnv.apiUrl.getSensorMapping,
      [assetContextPointId.toString()]);
    return this.httpClient.get(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getCurrentReadingData(dataSourceId): Observable<any> {

    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getCurrentReading,
      [dataSourceId.toString()]);
    return this.httpClient.get(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getCalculationDays(): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getRecalculationDays);
    return this.httpClient.get(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public postConversionByUnitType(obj): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.postConversionByUnitType);
    const body = JSON.stringify(obj);

    return this.httpClient.post(url, body).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  /**
   * @description fetches the currency list, site culture and sets the currency symbol to be displayed
   * @param siteCpId
   * @returns
   */
  public getSiteCurrenyAndCultureBySiteCpId(siteCpId): Observable<any> {
    return forkJoin([this.getSiteCultureBySiteCpId(siteCpId), this.getCurrencyData()]).pipe(map(res => {
      const siteCultureData = res[0];
      const currencyData = res[1];
      if (siteCultureData && Array.isArray(currencyData)) {
        const currencyObj = currencyData.find(currency => currency.CurrencyCodeId === siteCultureData.CurrencyCode)
        if (currencyObj) {
          this.siteCurrencySymbol = currencyObj.Symbol;
        }
      }
    }))
  }

  /**
   * @description fetches the available currency list
   * @returns
   */
  public getCurrencyData(): Observable<any> {
    if (this.currencyData) {
      return of(this.currencyData);
    } else {
      const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getCurrencies}`;
      return this.httpClient.get(url).map((currencyData) => {
        try {
          this.currencyData = currencyData;
          return this.currencyData;
        } catch (e) {
          return e;
        }
      })
    }

  }

  /**
   * @description fetches the active site culture
   * @param siteCpId
   * @returns
   */
  public getSiteCultureBySiteCpId(siteCpId): Observable<any> {
    if (this.siteCultureData[siteCpId]) {
      return of(this.siteCultureData[siteCpId]);
    } else {
      const url = `${this.configEnv.siteHarvesterServerUrl}${this.configEnv.apiUrl.getSiteCultureBySiteCpId}/${siteCpId}`;
      return this.httpClient.get(url).map((siteCultureData) => {
        try {
          this.siteCultureData[siteCpId] = siteCultureData;
          return siteCultureData;
        } catch (e) {
          return e;
        }
      })
    }
  }
  /**
 * @description replicate Edata measurements and limits from source asset to target assets
 * @returns boolean value
 */
  public replicateOrMoveMeasurementsAndLimits(obj): Observable<any> {
    const url = `${this.configEnv.msrAggregatorServerUrl}${this.configEnv.apiUrl.replicateOrMoveMeasurementsAndLimits}`;
    const body = JSON.stringify(obj);
    return this.httpClient.post(url, body).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  /**
 * @description checking if user has access to see the copy button
 * @returns boolean value if user has access or not
 */
  public isPilotUser(): Observable<boolean> {
    // Uncomment this lines after removing isPilot condition from all AH files
    // const url = `${this.configEnv.msrAggregatorServerUrl}${this.configEnv.apiUrl.isPilotUser}`;
    // return this.httpClient.get<boolean>(url).map((response: boolean) => {
    //   return response;
    // });

    // Remove this lines after removing isPilot condition from all AH files
    const observer$ : Observable<boolean> = new Observable(observer => {
      observer.next(true);
      observer.complete();
    });
    return observer$;
  }


  /**
   * @description fetches the available Data Source Ids based on Data Source Type
   * @param siteCpId
   * @param DataSourceType
   * @returns
   */
  public getDataSourceIdBySiteId(siteCpId: number, DataSourceType: string): Observable<any> {
    const url = this.parse(this.configEnv.siteHarversterAggrServerUrl + this.configEnv.apiUrl.getDataSourceBySiteId,
      [DataSourceType.toString(), siteCpId.toString()]);
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    })
  }

  /**
  * @description fetches the available Unmapped measurements based on Data Source Id
  * @param dataSourceCpId
  * @param dagCpId
  * @param CategoryId
  * @returns
  */
  getUnmapMsrByDsId(dataSourceCpId, dagCpId, CategoryId): Observable<any> {
    const url = this.parse(this.configEnv.msrAggregatorServerUrl + this.configEnv.apiUrl.getUnMappedMsrInstancesByDs,
      [dataSourceCpId, dagCpId, CategoryId]);
    return this.httpClient.get(url).map((res) => {
      try {
        return res;
      } catch (e) {
        return e;
      }
    })
  }

  /**
 * @description fetches the assets details based on SiteCpId for all categories
 * @param siteCpId
 * @param categoryId
 * @returns
 */
  public getAssetsDetailsByCategoryId(siteCpId: number, categoryId: number): Observable<any> {
    const url = this.parse(this.configEnv.siteHarversterAggrServerUrl + this.configEnv.apiUrl.getAssetsDetailsByCategoryId,
      [siteCpId.toString(), categoryId.toString()]);
    return this.httpClient.get(url).map((response) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    })
  }

  /**
   * @description duplicates an asset in a given site
   * @param sourContextPointId number
   * @param dagType string
   * @param assetCreatorType string
   * @param payload
   * @returns
   */
  public duplicateAsset(sourContextPointId: number, siteCpId: number, dagType: string, assetCreatorType: string, payload): Observable<any> {
    let url = this.parse(this.configEnv.siteHarversterAggrServerUrl + this.configEnv.apiUrl.duplicateAsset, [sourContextPointId.toString(), siteCpId.toString()]);
    url = url + `?dagType=${dagType}&assetCreatorType=${assetCreatorType}`;
    return this.httpClient.post(url, payload).map((response) => {
      try {
        return response;
      } catch (e) {
        return e;
      }
    })
  }

  public updateDuplicatedAssetsList(assetContextPointName: string, action: string) {
    switch (action) {
      case 'add': if (!this.duplicatedAssetsList.includes(assetContextPointName?.toLowerCase())) {
        this.duplicatedAssetsList.push(assetContextPointName?.toLowerCase());
      };
        break;
      case 'delete': const deleteItemIndex = this.duplicatedAssetsList.findIndex(cpName => cpName?.toLowerCase() === assetContextPointName?.toLowerCase());
        if (deleteItemIndex >= 0) {
          this.duplicatedAssetsList.splice(deleteItemIndex, 1);
        }
        break;
      default: break;
    }
  }

  public clearDuplicatedAssetsList() {
    this.duplicatedAssetsList = [];
  }

  public getAccessibleSitesStatistics(options: any, sitesType): Observable<ISite[]> {
    sitesType = sitesType ? sitesType : 'mySites';
    this.url = this.parse(this.configEnv.siteHarvesterServerUrl + this.configEnv.apiUrl.getAccessibleSitesStatistics);

    const obj: any = {
      "PageIndex": options.offset,
      "PageSize": options.limit,
      "SearchString": options.searchTerm,
      "SiteSearchType": sitesType,
      "Dag": "NalcoWater"
    };

    return this.httpClient.post(this.url, obj).map((response: ISite[]) => {
      try {
        this.siteList = response;
      } catch (e) {
        this.siteList = response;
      }
      return this.siteList;
    });
  }

  getCsvByDag(siteId: number): Observable<any> {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getCsvByDag, [siteId.toString()]);
    return this.httpClient.get(url).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    })
  }

  dagByCSVConverter(data: Array<model.AccountCSVExports>, siteiId: number) {
    let output = [];
    data.forEach(element => {
      const assetObject = {
        'Corporate Account Name': element.CorporateAccountName == undefined ? '' : element.CorporateAccountName,
        'SoldTo/ShipTo': element.SoldToShipTo == undefined ? '' : element.SoldToShipTo,
        'SiteName': element.SiteName == undefined ? '' : element.SiteName,
        'SiteID': element.SiteID == undefined ? '' : element.SiteID,
        'Customer Area Type': element.CustomerAreaType == undefined ? '' : element.CustomerAreaType,
        'Customer Area Name': element.CustomerAreaName == undefined ? '' : element.CustomerAreaName,
        'Customer Sub Area Type ': element.CustomerSubAreaType == undefined ? '' : element.CustomerSubAreaType,
        'System Type': element.SystemType == undefined ? '' : element.SystemType,
        'System Name': element.SystemName == undefined ? '' : element.SystemName,
        'Asset Type': element.AssetType == undefined ? '' : element.AssetType,
        'Asset Name': element.AssetName == undefined ? '' : element.AssetName,
        'Configuration Status': element.ConfigurationStatus == undefined ? '' : element.ConfigurationStatus,
        'Operational Status': element.OperationalStatus == undefined ? '' : element.OperationalStatus,
        'Managed Status': element.ManagedStatus == undefined ? '' : element.ManagedStatus,
        'Asset Port Name': element.AssetPortName == undefined ? '' : element.AssetPortName,
        'Controller Data Source Mapped': element.ControllerDataSourceMapped == null ? '' : element.ControllerDataSourceMapped,
        'E-Data Source Mapped': element.EDataDataSourceMapped == null ? '' : element.EDataDataSourceMapped,
        'LIMS Data Source Mapped': element.LimsDataSourceMapped == null ? '' : element.LimsDataSourceMapped,
        'Measurement': element.MeasurementName == null ? '' : element.MeasurementName,
        'Water Quality Enabled': element.waterQualityEnable == null ? '' : element.waterQualityEnable,
        'Unique Identifier': element.UniqueIdentifier == null ? '' : element.UniqueIdentifier,
        'Units': element.Units == undefined ? '' : element.Units,
        'Limit Type': element.LimitType == undefined ? '' : element.LimitType,
        'Target': element.Target == undefined ? '' : element.Target,
        'Lower Limit': element.LowerLimit == undefined ? '' : element.LowerLimit,
        'Upper Limit': element.HighLimit == undefined ? '' : element.HighLimit,
        'Limit Effective Date2': element.LimitEffectiveDate2 == null ? '' : element.LimitEffectiveDate2,
        'Last Measurement Date': element.LastMeasurementDate == null ? '' : element.LastMeasurementDate,
        'Last Measurement Value': element.MeasurementValue == null ? '' : element.MeasurementValue,
      };
      output.push(assetObject);
    })
    return output;
  }

  public ExportAsCSV(data: any) {
    const exportData = this.removeVulnerables(data);
    //this.angularFileName = this.generateFileName();
    this.exportData = exportData;
    this.csvoptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      headers: Object.keys(exportData[0]),
      showTitle: true,
      removeNewLines: true,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // new Angular2Csv(exportData, filename, { headers: Object.keys(exportData[0]) });
    new AngularCsv(exportData, this.fileName, { headers: Object.keys(exportData[0]) });
  } public removeVulnerables(exportData: any) {
    for (const propertyKey in exportData) {
      if (exportData.hasOwnProperty(propertyKey)) {
        for (const i in exportData[propertyKey]) {
          if (typeof (exportData[propertyKey][i]) === "string") {
            if (VULNERABLE_CHAR.find(it => it === (((exportData[propertyKey][i])).substr(0, 1)))) {
              exportData[propertyKey][i] = "'" + (exportData[propertyKey][i]);
            }
          }
        }
      }
    }
    return exportData;
  }

  public getSitebyID(siteIdValue: number) {
    const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSiteBySiteId,
      [siteIdValue.toString()]);
    return this.httpClient.get<any>(url).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  getAccountDetails(siteId: number): Observable<AccountDetails[]> {
    const url = this.parse(this.configEnv.siteHarvesterServerUrl + this.configEnv.apiUrl.getAccountDetails, [siteId.toString()]);
    return this.httpClient.get<AccountDetails[]>(url).map(response => {
      try {
        return response;
      }
      catch (e) {
        return response;
      }
    })
  }

  getSourceDetails(siteId: number): Observable<any> {
    const url = this.parse(this.configEnv.siteHarvesterServerUrl + this.configEnv.apiUrl.getSourceDetails, [siteId.toString()]);
    return this.httpClient.get<any>(url).map(response => {
      try {
        return response;
      }
      catch (e) {
        return response;
      }
    })
  }


  /**
   * @description saves Toatl Planned Assets count for the site
   * @param siteCpId number
   * @param noOfAssets number
   * @returns observable
   */
  saveTotalAssetsPlanned(siteCpId: number, noOfAssets: number): Observable<any> {
    const url = this.parse(this.configEnv.siteHarvesterServerUrl + this.configEnv.apiUrl.saveTotalAssetsPlanned, [siteCpId.toString(), noOfAssets.toString()]);
    return this.httpClient.post<any>(url, {}).map(response => {
      try {
        return response;
      }
      catch (e) {
        return response;
      }
    })
  }

  public siteAssetsExport(siteId: any): Observable<any> {
    this.url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSiteAssetForExport + '/' + siteId);
    return this.httpClient.get(this.url).map((response: any) => {
        try {
            return response;
        } catch (e) {
            return response;
        }

    });
  }
  public saveAssetsCollectionNew(siteCpId: number, assetCreatorType, categoryId: number, subCategoryId: number, payload): Observable<any> {
    let url = '';
    url = this.parse(this.configEnv.siteHarversterAggrServerUrl + this.configEnv.apiUrl.upsertSiteLayout, [siteCpId.toString()]);
    url = url + `?userId=0&assetCreatorType=${assetCreatorType}&categoryId=${categoryId}&subCategoryId=${subCategoryId}`;
    return this.httpClient.post(url, payload).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }
  
  isOpStatusDisabled(){
  this.getOpSchTabDisabled(this.invalidOpSchedule || this.inValidOfflineSch);
}

  public siteLayoutExport(siteId: string, categoryId: number, isAssetAdded: boolean): Observable<any> {
    this.url = this.parse(
      `${this.configEnv.siteHarvesterServerUrl}${this.configEnv.apiUrl.
        getSiteLayoutBySiteCpId}/${siteId}?` +
      `contextPointPropertyType=ContextPointProperties&getAllChildren=false&isLite=false&categoryId=${categoryId}`
    );
    const requestBody = [
      {
        GraphItemType: "ContextPointType",
        GraphItemTypeValue: "9000",
        GraphItemValueType: "",
        GraphItemValues: []
      }
    ];

    let key = siteId + '_' + categoryId;
    if (this.assetsAccToCategory[key] && !isAssetAdded) {
      return of(this.assetsAccToCategory[key]);
    } else {


      return this.httpClient.post<any>(this.url, requestBody).pipe(
        map((response: any) => {
          try {
            return response;
          } catch (e) {
            console.error('Error processing response:', e);
            return response;
          }
        }),
        catchError((error) => {
          console.error('Error during HTTP request:', error);

          throw error;
        })
      );
    }
  }
}
