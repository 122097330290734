import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupAssetByCategory'
})
export class GroupAssetByCategoryPipe implements PipeTransform {

  transform(assets: any[], category: any): any {
    let coll = [];
    let mappedLen = 0;
    if (assets && category && category.AssetsContextPointSubTypeId) {
      assets.forEach(asset => {
        if (asset.CategoryId == category.CategoryId && category.AssetsContextPointSubTypeId.indexOf(asset.ContextPointSubTypeId) !== -1) {
          if (asset.IsMapped) {
            mappedLen++;
          } 
          coll.push(asset);
        }
      });
      if (coll && coll.length >= 1 && coll.length === mappedLen) {
        category.OverAllMappingStatus = true;
      }
    }
    return coll;
  }
  
}
