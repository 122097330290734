import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsErrorHandler } from '../../auth/appInsightsErrorHandler';
import { ApmErrorHandler} from '@elastic/apm-rum-angular';

@Injectable({
  providedIn: 'root'
})
export class CompositeErrorHandlerService implements ErrorHandler {
  constructor(
    private applicationInsightsErrorHandlerService:ApplicationInsightsErrorHandler,
    private elasticAPMErrorHandlerService: ApmErrorHandler
  ) {}

  handleError(error: any): void {
    this.applicationInsightsErrorHandlerService.handleError(error);
    this.elasticAPMErrorHandlerService.handleError(error);
  }
}