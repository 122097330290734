/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Injectable } from '@angular/core';
import { ENVIRONMENTS, APP_CONFIG } from './../../../app.config';
import { Config } from './../contracts/configEnv'
@Injectable({
    providedIn: 'root'
})

export class GlobalSearchFiltersServiceConfig {

    private configMock: Config = { // MOCK - local json settings
        environment: ENVIRONMENTS.mock,
        baseUrl: '',
        apiUrl: {
            getContextSearch: 'api/v1/GetContextSearch',
            getSitesUserHasAccessTo: 'api/GetSitesUserHasAccessTo',
            saveUserContextSearchHistory : 'api/v1/CreateUserContextSearchHistory',
            getUserContextSearchHistory : 'api/v1/GetUserContextSearchHistory',
            getDagAccountChildren: 'DagCorporateAccountChildren/',
            getDagParentEdgeChildren: 'DagParentEdgeChildren/',
            getMySites : 'SiteManagement/api/v1/GetAccessibleSitesStatistics'

        }

    };

    private configProd: Config = { // PRODUCTION - PRODUCTION ENVIRONMENT SETTINGS
        environment: ENVIRONMENTS.prod,
        baseUrl: '',
        apiUrl: {
            getContextSearch: 'api/v1/GetContextSearch',
            getSitesUserHasAccessTo: 'api/GetSitesUserHasAccessTo',
            saveUserContextSearchHistory : 'api/v1/CreateUserContextSearchHistory',
            getUserContextSearchHistory : 'api/v1/GetUserContextSearchHistory',
            getDagAccountChildren: 'DagCorporateAccountChildren/',
            getDagParentEdgeChildren: 'DagParentEdgeChildren/',
            getMySites : 'SiteManagement/api/v1/GetAccessibleSitesStatistics'

        }
    };

    private _environment: string = APP_CONFIG.ENVIRONMENT;

    constructor() {
        // void
    }

    public getEnvironment(): Config  {
        switch (this._environment) {
            case ENVIRONMENTS.mock:
                return this.configMock;
            case ENVIRONMENTS.dev:
                return this.configProd;
            case ENVIRONMENTS.test:
                return this.configProd;
            case ENVIRONMENTS.stg:
                return this.configProd;
            case ENVIRONMENTS.prod:
                return this.configProd;
            default:
                return null;
        }
    }

    public setEnvironment(environment: string): any {
        this._environment = environment;
    }

}
