import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdown, IDropdownEventType } from '../dropdown/contracts/dropdown-model';
import { IButtonData } from '../button/contracts/button-model';
import { PopupSettings } from '@progress/kendo-angular-popup';
import moment from 'moment';

@Component({
  selector: 'custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomDatePickerComponent implements OnInit  {
  @Input() isDisabled = false;
  @Input() dateRanges: IDropdown;
  @Input() bottomView = 'month';
  @Input() showApplyButton = true;
  @Input() sDate: Date;
  @Input() eDate: Date;
  @Input() dateFormat?: string;
  disabled = false;
  public popupSettings: PopupSettings = {
    popupClass: "time-range-datepicker",
  };
  buttonPrimary: IButtonData;

  public showOtherClassic = true
  updatedData: IDropdownEventType;
  @Output('updateDate') updateDate = new EventEmitter<IDropdownEventType>();
  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.buttonPrimary = {
      buttonClass: "custom", buttonText: this.translate.instant('COMMON.APPLY'),
      customClass: 'primary-small'
    }
     this.sDate = this.sDate ? new Date(moment(this.sDate).utc().toString()) : this.sDate;
     this.eDate = this.eDate ? new Date(moment(this.eDate).utc().toString()) : this.eDate;      
  }

  // this is removed for the tempororay fix for the all filters comtainer need to test more use cases on the same post release

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.sDate?.currentValue) {
      this.sDate = new Date(moment(changes?.sDate?.currentValue).utc().toString());
    }
    else if(changes?.eDate?.currentValue){
      this.eDate = new Date(moment(changes?.eDate?.currentValue).utc().toString());
    }
  }

  /**
   * @description  on calender date change event
   * @param string
   * @param Date
   */
  
  onChange(value: Date, param: string) {
    if (param === 'startDate') {
      this.sDate = value ? value : undefined;
    } else if (param === 'endDate') {
      this.eDate = value ? value : undefined;
    }
      if (!this.showApplyButton) {
        this.updatedData = { name: 'Custom', value: 'Custom', startDate: typeof(this.sDate) === 'string' ? this.sDate : this.sDate.toISOString(), 
          endDate: typeof(this.eDate) === 'string' ? this.eDate : this.eDate.toISOString() };
        this.updateDate.emit(this.updatedData);
      }
  }
  public disabledEndDates = (date: Date): boolean => {
    return date < this.sDate;
  }

  disabledStartDates = (date: Date): boolean => {
    if (this.eDate !== null) {
      return date > this.eDate;
    }
  }
  /**
 * @description  on calender date change and click on apply button 
 */
  applySelectedDate() {
    if (this.sDate && this.eDate) {
      this.disabled =
        this.sDate.getTime() >= this.eDate.getTime() ? true : false;
      this.updatedData = { name: 'Custom', value: 'Custom', startDate: this.sDate?.toISOString(), endDate: this.eDate?.toISOString() };
      this.updateDate.emit(this.updatedData);
    }
  }


  /**
 * @description  on click of clear button 
 */
  clearFilters() {
    if (this.sDate || this.eDate) {
      this.sDate = null;
      this.eDate = null;
    }
  }



}

