import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AssetHarvesterVersion } from '../_enum/constants';
import { UserService } from '../../core/user/user.service';
import { LocalCacheService } from '../../core/local-cache/local-cache.service';


@Injectable({
  providedIn: 'root'
})
export class AHNavigationResolverService implements Resolve<any> {

  constructor(private router: Router,
    private localCacheService: LocalCacheService,
    private userService: UserService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const currentUrl = state.url;
    const id = route['_routerState'].url;
    if (id === '/assetharvester') {

        const userAHPreference = JSON.parse(this.localCacheService.fetchData('UserUXPreference', 'session'));
        if(userAHPreference){
          let AHPreference = userAHPreference.findIndex( preference => preference.ServiceType == 'AssetHarvesterBuilder');
          (AHPreference !== -1 && userAHPreference[AHPreference].UXVersion == AssetHarvesterVersion['AssetHarvester2'].version)?
          this.localCacheService.storeData('AH_version', AssetHarvesterVersion['AssetHarvester2'].version, 'session') :
          this.localCacheService.storeData('AH_version', AssetHarvesterVersion['AssetHarvester1'].version, 'session');
        }
        const currentVersion = this.localCacheService.fetchData('AH_version', 'session');
        const version = Object.values(AssetHarvesterVersion).find(v => v.version === currentVersion);
  
        if (version && currentUrl !== version.baseUrl) {
          this.router.navigate([version.baseUrl]);
        }
        return null;
     // })
    }
    else{
      return null
    }

  }
}
