// angular modules packages
import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { of, Subject } from 'rxjs';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

// custom modules components
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { AppEnvConfig } from '../../app.env.config';
import { SourceApplicationTypeId } from '../_enum/constants';
import { IActionLog, IActionLogRecommendations } from '../contracts/IActionLog';
import { ActionLogUser, EroiTypes, SavingCategoryTypes } from '../models/ActionLog';
import { ActionLogServiceConfig } from './action-log.service.config';

@Injectable()
export class ActionLogService extends BehaviorSubject<GridDataResult> {

  // declare all the variables
  private configEnv: any;
  public url: string;
  public contextPoint: number;
  public jsonServerUrl: string;
  public dagType: string;
  public checkMaxDags: string;
  public updateDateValues: any;
  public currentSwitchValue: boolean = false;
  public isDataSaved: boolean = false;
  public saveDetailsSubscribed: boolean = false;
  private columnsSelected = new BehaviorSubject<any>([]);
  displaySelectedColumns = this.columnsSelected.asObservable();

  public filterValue = new BehaviorSubject<any>(null);
  $filterValue = this.filterValue.asObservable();
  private assetsData: { [key: string]: any[] } = {};

  public saveDuringEdit = new Subject<any>();
  $saveDuringEdit = this.saveDuringEdit.asObservable();

  private isDirty = new Subject<boolean>();
  $isDirty = this.isDirty.asObservable();

  private showLoaderSubject = new Subject<boolean>();
  $showLoader = this.showLoaderSubject.asObservable();

  getUpdateFilterObj(filterObj: any) {
    this.filterValue.next(filterObj);
  }

  getSaveParamsObj(saveObj: any) {
    this.saveDuringEdit.next(saveObj);
  }

  checkColumnsChange(columns) {
    this.columnsSelected.next(columns);
  }

  checkColumns(selectedColumns, ActionLogTableColumns) {
    if (selectedColumns != null && selectedColumns !== undefined && selectedColumns.length > 0) {
      return selectedColumns
    } else {
      ActionLogTableColumns.forEach(item => {
        if (item.defaultActive) {
          let defaultData = [];
          defaultData.push(item.title);
          return defaultData
        }
      });
    }
  }

  _toggleSettingType = new BehaviorSubject<{ widgetId: number, type: "open" | "closed" }>(null);
  //_toggleSettingType = new BehaviorSubject<{widgetId: number, type: 2 | 4}>(null);

  get toggleSettingType() {
    return this._toggleSettingType.asObservable();
  }

  setToggleSettingType(data: { widgetId: number, type: "open" | "closed" }) {
    this._toggleSettingType.next(data);
  }
  // constructor
  constructor(
    private sharedServiceConfig: ActionLogServiceConfig,
    private httpClient: HttpClient,
    private config: AppEnvConfig) {

    super(null);
    this.configEnv = sharedServiceConfig.getEnvironment();
    this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
    this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
    this.configEnv.apiServerUrlAuth = config.getEnv('apiServerUrl');
    this.configEnv.apiWaterServerUrl = config.getEnv('apiWaterServerUrl');
    this.configEnv.actionlogServerUrl = config.getEnv('actionlogServerUrl');
    this.configEnv.contextPoint = 0;
    this.dagType = 'NalcoWater/';
    this.checkMaxDags = '/true/';
  }

  showLoader(flag) {
    this.showLoaderSubject.next(flag);
  }

  changeDirty(flag: boolean) {
    this.isDirty.next(flag);
  }

  public getActionLogRecommendation(contextPointId: number, startDate: string, endDate: string,
    actionStatusType: string, groupCpId?: number, recommendationsWithSavingsOnly?: boolean, selectedBusinessUnit?: string, isUnaligned?: boolean): Observable<IActionLogRecommendations> {
    // eslint-disable-next-line max-len
    if (recommendationsWithSavingsOnly) {
      isUnaligned = isUnaligned ? isUnaligned : false;
      this.url = groupCpId
        ? `${this.configEnv.apiServerUrlAuth}${this.configEnv.apiUrl.getActionLogRecommendation}${contextPointId}/${startDate}/${endDate}/${actionStatusType}?recommendationsWithSavingsOnly=${recommendationsWithSavingsOnly}&isUnaligned=${isUnaligned}/groupCpId=${groupCpId}`
        : selectedBusinessUnit == 'All' ? `${this.configEnv.apiServerUrlAuth}${this.configEnv.apiUrl.getActionLogRecommendation}${contextPointId}/${startDate}/${endDate}/${actionStatusType}?recommendationsWithSavingsOnly=${recommendationsWithSavingsOnly}&isUnaligned=${isUnaligned}`
          : selectedBusinessUnit == 'WT' ? `${this.configEnv.apiServerUrlAuth}${this.configEnv.apiUrl.getActionLogRecommendation}${contextPointId}/${startDate}/${endDate}/${actionStatusType}?recommendationsWithSavingsOnly=${recommendationsWithSavingsOnly}&divisions=${selectedBusinessUnit}&divisions=PA&divisions=DS&divisions=EN&isUnaligned=${isUnaligned}`
            : `${this.configEnv.apiServerUrlAuth}${this.configEnv.apiUrl.getActionLogRecommendation}${contextPointId}/${startDate}/${endDate}/${actionStatusType}?recommendationsWithSavingsOnly=${recommendationsWithSavingsOnly}&divisions=${selectedBusinessUnit}&isUnaligned=${isUnaligned}`;
    } else {
      this.url = groupCpId
        ? `${this.configEnv.apiServerUrlAuth}${this.configEnv.apiUrl.getActionLogRecommendation}${contextPointId}/${startDate}/${endDate}/${actionStatusType}?groupCpId=${groupCpId}`
        : `${this.configEnv.apiServerUrlAuth}${this.configEnv.apiUrl.getActionLogRecommendation}${contextPointId}/${startDate}/${endDate}/${actionStatusType}`;
    }

    return this.httpClient.get(this.url)
      .map((response: any) => response);
  }

  public saveActionLogRecommendation(actionLog): Observable<IActionLog> {
    const url = this.configEnv.actionlogServerUrl + this.configEnv.apiUrl.saveActionLogRecommendation;
    return this.httpClient.put(url, actionLog).map((response: any) => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getsites(dropDownPaging): Observable<any> {
    const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getsites;
    const body = JSON.stringify(dropDownPaging);
    return this.httpClient.post(url, body).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getsitesByCorporate(corporateIds: Array<number>): Observable<any> {
    const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getSitesForCorporates;
    const body = JSON.stringify(corporateIds);
    return this.httpClient.post(url, body).map(response => {
      try {
        return response;
      } catch (e) {
        return response;
      }
    });
  }

  public getAssets(duration: number, siteId: number, startdate?: string, enddate?: string): Observable<any> {
    const key = `AssetData-${duration}-${siteId}-${startdate}-${enddate}`;
    if (this.assetsData[key]) {
      return of(this.assetsData[key]);
    } else {
      this.url = `${this.configEnv.apiServerUrlAuth}${this.configEnv.apiUrl.getAssets}/${duration}/${siteId}`;
      if (duration === 0) {
        this.url += `/${startdate}/${enddate}`;
      }
      this.url += '?includeSoldtos=true';
      return this.httpClient.get(this.url)
        .map((response: any) => {
          this.updateAssetsData(duration, siteId, startdate, enddate, response)
          return response
        });
    }
  }

  public getResponsiblePerson(siteId, searchKey): Observable<any> {
    this.url = `${this.configEnv.apiServerUrlAuth}${this.configEnv.apiUrl.getResponsiblePerson}/${siteId}/${encodeURIComponent(searchKey)}/UserSearch`;
    return this.httpClient.get(this.url)
      .map((response: any) => response);
  }

  public getFileAttachmentLink(attachmentId): Observable<any> {
    this.url = `${this.configEnv.apiServerUrlAuth}${this.configEnv.apiUrl.getAttachmentUri}/${attachmentId}/1`;
    return this.httpClient.get(this.url)
      .map((response: any) => response);
  }

  public getSoldTosBySiteContextPointId(siteId): Observable<any> {
    this.url = `${this.configEnv.apiServerUrlAuth}${this.configEnv.apiUrl.getSoldTosBySiteContextPointId}/${siteId}`;
    return this.httpClient.get(this.url)
      .map((response: any) => response);
  }

  public getStartEndDates(days: number): any {
    return {
      'duration': days,
      'startDate': moment().subtract(days, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      'endDate': moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    };
  }

  public getObjInCollOnValue(coll: any, value: string): any {
    const obj = coll.filter((x) => {
      x.checked = false;
      if (String(x.value).toLocaleLowerCase() === value.toLowerCase()) {
        x.checked = true;
        return x;
      }
    });
    return obj;
  }

  public getObjInCollOnId(coll: any, id: string): any {
    const obj = coll.filter((x) => {
      if (x.id === id) {
        x.checked = true;
        return x;
      }
    });
    return (obj && obj.length) ? obj[0] : null;
  }

  // Check if ContextPointId is null, pass -1 as parameter if this is the case. Otherwise return set ContextPointId
  public checkNull(contextPointId: string) {
    // eslint-disable-next-line radix
    const int = parseInt(contextPointId);
    if (isNaN(int)) {
      return -1;
    } else {
      return int;
    }
  }

  public updateAssetsData(duration: number, siteId: number, startdate: string, enddate: string, data) {
    const key = `AssetData-${duration}-${siteId}-${startdate}-${enddate}`;
    this.assetsData[key] = [...data];
  }

  public clearAssetData() {
    this.assetsData = {};
  }

  public getEroiTypes(): Observable<Array<EroiTypes>> {
    const url = this.configEnv.apiWaterServerUrl + this.configEnv.apiUrl.getEroiTypes;
    return this.httpClient.get<Array<EroiTypes>>(url);
  }

  public getSavingCategoryTypes(): Observable<Array<SavingCategoryTypes>> {
    const url = this.configEnv.apiWaterServerUrl + this.configEnv.apiUrl.getSavingCategoryTypes;
    return this.httpClient.get<Array<SavingCategoryTypes>>(url);
  }

  /**
   * @description to get value of sourceApplicationTypeId
   * @param value is an sourceApplicationTypeId
   * @returns return The name of the source application associated with the given TypeId.
   */
  public getSourceApplicationType(value: number): string {
    return SourceApplicationTypeId[value];
  }
}
