
export class NewContextPoint implements IContextPoint {
    ContextPointIdParent: number = 0;
    ContextPointNameParent: string = '';
    ContextPointAdditionalInfo = {};
    ContextPointDescription: string = '';
    IsVisible: number = 1;
    ContextPointProperties = {};
    Children?: Array<ContextPointChildren> = [new ContextPointChildren()];
    ContextPointSourceType?: string = '';
    ContextPointSourceTypePrettyName?: string = '';
    CreatedOn?: string = '';
    RetiredOn?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    ContextPointId: number = 0;
    ContextPointName: string = '';
    ContextPointStatusType?: number = -1;
    ContextPointStatusTypePrettyName?: string = '';
    ContextPointType?: string = '';
    ContextPointTypePrettyName?: string = '';
    ContextPointSubTypeId?: number = 0;
    ContextPointSubTypeName?: string = '';
}
export class ContextPointChildren implements IContextPointChildren {
    ContextPointIdParent: number;
    ContextPointDescription: string;
    IsVisible: number = 1;
    ContextPointProperties: {};
    Children: IContextPointChildren[];
    ContextPointSourceType?: string = '';
    ContextPointSourceTypePrettyName?: string = '';
    CreatedOn?: string = '';
    RetiredOn?: string = '';
    ModifiedOn?: string = '';
    ModifiedBy?: number = 0;
    ContextPointId?: number = 0;
    ContextPointName?: string = '';
    ContextPointStatusType?: number = -1;
    ContextPointStatusTypePrettyName?: string = '';
    ContextPointType?: string = '';
    ContextPointTypePrettyName?: string = '';
    ContextPointSubTypeId?: number = 0;
    ContextPointSubTypeName?: string = '';
    constructor(sp?: any) {
        // this.cpSubTypeProperties_PropertyName_SystemProduceQuestion = {
        //     PropertyName: What does this System produce?;
        //     ValueName: Produce_System;
        //     PropertyId: 317;
        //     ValueId: 1;
        //     NumericValue: 0
        // }
    }
}
export interface IContextPoint {
    ContextPointIdParent: number;
    ContextPointNameParent: string;
    ContextPointAdditionalInfo: any;
    ContextPointDescription: string;
    IsVisible: number;
    ContextPointProperties: any;
    Children?: Array<ContextPointChildren>;
    ContextPointSourceType?: string;
    ContextPointSourceTypePrettyName?: string;
    CreatedOn?: string;
    RetiredOn?: string;
    ModifiedOn?: string;
    ModifiedBy?: number;
    ContextPointId: number;
    ContextPointName: string;
    ContextPointStatusType?: number;
    ContextPointStatusTypePrettyName?: string;
    ContextPointType?: string;
    ContextPointTypePrettyName?: string;
    ContextPointSubTypeId?: number;
    ContextPointSubTypeName?: string;
}
export interface IContextPointChildren {
    ContextPointIdParent: number;
    ContextPointDescription: string;
    IsVisible: number;
    ContextPointProperties: {};
    Children: IContextPointChildren[];
    ContextPointSourceType?: string;
    ContextPointSourceTypePrettyName?: string;
    CreatedOn?: string;
    RetiredOn?: string;
    ModifiedOn?: string;
    ModifiedBy?: number;
    ContextPointId?: number;
    ContextPointName?: string;
    ContextPointStatusType?: number;
    ContextPointStatusTypePrettyName?: string;
    ContextPointType?: string;
    ContextPointTypePrettyName?: string;
    ContextPointSubTypeId?: number;
    ContextPointSubTypeName?: string;
}
const NewAssetCollection = new NewContextPoint();
NewAssetCollection.Children.forEach((child: IContextPointChildren) => {
    child.Children = [new ContextPointChildren()];
})
